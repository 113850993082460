
.component-syllabus-coach-assitant{
    
}.input-syllabus-search > .rs-input {
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  .mtr{
    margin-left: 6%;
  }
  .add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .tope{
    top: 20px;
  }
  .icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
  }
  /* radio button */
  .btn-archive {
    /* background-color: white;
    font-size: 14px;
    font-weight: bold;
    color: #4b9b77; */
  
    font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 17px;
      /* color: white; */
      /* background: transparent; */
      display: flex;
      align-items: center;
      transition: .2s ease-in-out;
      /* border-radius: 1rem; */
      height: 36px;
      background: #469789;
      /* width: 100%; */
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 0.5rem;
  }
  .radiobtn {
    position: relative;
    display: block;
    padding: 1.2em;
  }
  .radiobtn label {
    display: block;
    background: #05a192cc;
    color: #fff;
    border-radius: 5px;
    padding: 8px 18px;
    border: 2px solid #009688cc;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .radiobtn label:after,
  .radiobtn label:before {
    content: "";
    position: absolute;
    right: 11px;
    top: 11px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #009688cc;
  }
  .tn-theme-light{
    padding: 0.5rem 1rem;
    background: #ffffff;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    color: #069a77;
    border: 1px solid transparent;
    
  }
  .radiobtn label:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
  }
  .radiobtn input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    appearance: none;
  }
  .radiobtn input[type="radio"]:checked + label {
    background: #05a192cc;
    animation-name: blink;
    animation-duration: 1s;
    border-color: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:after {
    background: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:before {
    width: 20px;
    height: 20px;
  }
  @keyframes blink {
    0% {
      background-color: #05a192cc;
    }
    10% {
      background-color: #05a192cc;
    }
    11% {
      background-color: #009688cc;
    }
    29% {
      background-color: #009688cc;
    }
    30% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    45% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    100% {
      background-color: #009688cc;
    }
  }
  
  .col-lg-6.col-md-4.col-sm-6.mb-4.px-lg-3.px-md-2 {
    display: inline-block;
  }
  
  .recherche{
    border-radius: 2rem;
    width: -webkit-fill-available;
    height: 33px;
    border-color: #009688cc;
  }
  
  
  