.date-reunion-zoom-card-dashboard-bakeliste{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #009688;
    margin-bottom: 35px;
}
.btn-reunion-zoom-card-dashboard-bakeliste{
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: .8rem 1rem .8rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid transparent;
    transition: .2s ease-in-out;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: rgba(0, 0, 0, 0.75);
}
.btn-reunion-zoom-card-dashboard-bakeliste:hover{
    text-decoration: none;
    border: 1px solid #069A77;
    color: #069A77;
}
.icon-btn-reunion-zoom-card-dashboard-bakeliste {
    width: 29px;
    position: absolute;
    left: 20px;
}
