
.component-demande-absence{
    
}

.label-danger{
    background-color: #eb4234;
    color:white;
    border-radius: 7px;
    font-weight: bold;
    text-align: center;
    padding: 10%;

    
}
.editColor{
    color: #469789;
}

.voirColor{
    color: #f39831;
}
.label-warning{
    background-color:#f39831;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    padding: 10%;


}

.label-success{
    background-color: #469789;
    color:white;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    padding: 10%;


}
.changed-status-demande {
    background: #f39831 !important;
    color: #000;
  }
  