.component-cv-bakelistes {
}
.validation-btn-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.validation-btn-container i {
  font-size: 62px;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn {
  width: 90px;
  height: 90px;
  border-radius: 15% 50% 15% 50%;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn:hover {
  background: #009688 !important;
}

.component-card-profil {
}

.card-profil-contain {
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 95%;
}
.image-profil {
  width: 250px;
  height: 250px;
}
.contain-card {
  display: block;
  text-align: center;
  justify-content: center;
}
.nom-profil {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #009688;
}
.contain-profil {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: rgba(0, 0, 0, 0.8);
}

/* .breadcrumb {
    background-color: white;
} */
.lien_nav {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.lien_nav:hover {
  list-style: none;
  text-decoration: none;
  color: #666666;
}
.lien_nav_current {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #009688 !important;
  padding-left: 1rem;
}

.component-card-info {
}
.modal-dialog {
  max-width: 1100px;
}
.titre-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #009688;
}
.input-texte {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.label-texte {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #666666;
}
.texte-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
}
.contain-info {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 2rem;
}
.btn-presentation {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  border: 1px solid #009688;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 50%;
}
.texte-info-contain {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  padding-left: 17rem;
}
.marge-contain {
  padding-top: 7rem;
}
.info-contain-right {
  text-align: right;
}
.video-modal {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .info-contain-left {
    text-align: left;
    padding: -1rem;
    margin: 0;
  }
  .texte-info-contain {
    padding-left: 0;
  }
  .btn-presentation {
    padding-left: -1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .form-info {
    display: block;
    width: 100%;
  }

  .texte-info-contain {
    padding-left: 0;
  }
  .presentation-info {
    text-align: left;
  }
  .btn-presentation {
    width: 100%;
    padding: 1rem;
  }
  .titre-info {
    font-weight: 200;
    font-size: 18px;
  }
}

.info-container {
  background: #ffffff;
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.title-cv {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #009688;
}

/* Accordion */

.accordion__container {
  margin-bottom: 10px;
  background-image: linear-gradient(to bottom left, #ffffff, #00968840);
  background-repeat: no-repeat;
  padding: 1em 2em;
  box-shadow: 0 5px 8px rgb(255, 255, 255);
  width: 100%;
}
.accordion__container:last-type {
  margin-bottom: 0;
}
p.accordion__answer {
  color: black;
  font-family: Montserrat;
  line-height: 21px;
}
.accordion__question__container {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8em;
}
.accordion__question {
  color: orangered;
}
.accordion__btn {
  display: inline-block;
  background: none;
  border: none;
  border: 2px solid currentColor;
  border-radius: 3px;
  color: #e08152;
  cursor: pointer;
}
.accordion__btn i {
  font-size: 1.2em;
  padding: 0.15em;
}
.accordion__answer__container {
  display: none;
}
.active__accordion {
  display: block;
}
span.diplome_label {
  font-weight: 600;
}
h5.project_name_label {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  color: #000000;
}
