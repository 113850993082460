.btn-show-modal-bakeliste-details{
    padding: 2px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
    background: transparent;
}
.avatar-bakeliste{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.nom-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
.formation-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #011F3B;
}
.position-action-bakeliste-details{
    position: absolute;
    top: 60px;
    right: 20px;
}
.btn-action-bakeliste-details{
    background: #FFFFFF;
    border-radius: 35px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    text-align: justify;
    text-transform: uppercase;
    color: #010101;
    width: 100%;
    transition: .2s ease-in-out;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.btn-action-bakeliste-details:hover{
    color: #069A77;
    transition: .2s ease-in-out;
    text-decoration: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

}
.icon-btn-action-bakeliste-details{
    width: 14px;
    margin-right: 10px;
    color: #069A77;
    height: auto;
}
.delete-btn-action-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    text-align: justify;
    text-transform: uppercase;
    color: #F96B57;
    transition: .2s ease-in-out;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background: #FFFFFF;
    border-radius: 35px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    width: 100%;
}
.delete-btn-action-bakeliste-details:hover{
    background-color: #F96B57;
    color: #ffffff;
    transition: .2s ease-in-out;
}
.icon-delete-btn-action-bakeliste-details{
    width: 14px;
    margin-right: 10px;
    height: auto;
}
.content-informations-bakeliste-details{
    padding: 15px 25px 0;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
}
.titre-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    margin-bottom: 25px;
}
.h-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
}
.text-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
}
.text-content-informations-bakeliste-details.mail-bakeliste{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}