.navigation-previous-account {
    position: absolute;
    left: -20px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
    top: -1px;
}
.navigation-previous-account:hover {
    color: #069A77;
}
.titre-navigation-previous-account{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #011F3B;
}

.label-account{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5A5A5A;
    margin-bottom: 3px;
}
.input-account{
    background: #FAFAFA;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    border: 0;
    line-height: 17px;
    color: #000000;
    transition: .2s ease-in-out;
}
.input-account:focus{
    background-color: #069a7721;
    transition: .2s ease-in-out;
}
.avatar-account{
    width: 100%;
    border-radius: 12px;
}
.upload-new-avatar-account>.rs-uploader-trigger>.rs-uploader-trigger-btn{
    background: #FAFAFA;
    border-radius: 10px;
    border: transparent;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #5A5A5A;
}
.delete-icon-avatar-account{
    position: absolute;
    right: -30px;
    top: 5px;
    color: #F42D2D;
    padding: 0;
    background: transparent;
}
.icon-avatar-account{
    width: 16px;
}
