
.component-voir-contenus-coach{
    
}
.component-contenu-liste {
}

@import "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-97/variables.less";
@import "https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/mixins.less";
body {
  background: #f2f2f2;
  font-family: "proxima-nova-soft", sans-serif;
  font-size: 14px;
}
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: white;
  min-width: 270px;
  height: 470px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.post-module:hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.5;
}
.post-module .thumbnail {
  background: white;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  color: white;
  font-weight: 700;
  text-align: center;
}

.post-module .thumbnail .auteur {
  position: absolute;
  top: 20px;
  /* right: 20px; */
  z-index: 1;
  background: #009688;
  width: 39%;
  height: 43px;
  padding: 12.5px 0;
  color: white;
  font-weight: 700;
  text-align: center;
  text-decoration: auto;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  /* padding: 30px; */
}
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #e74c3c;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: black;
  font-size: 18px;
  height: 24vh;
  line-height: 7vh;
  font-weight: 600;
}

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  color: #3f4142;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #ff9800;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: bisque;
  text-decoration: none;
}
.hover .post-module:hover all .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.container-content:before,
.container-content:after {
  content: "";
  display: block;
  clear: both;
}
.container .column {
  width: 100%;
  /* padding: 0 25px; */
  height: 100%;
  /* float: left; */

  
  padding: 2rem 2rem 3rem ;
}
.container .column .demo-title {
  margin: 0 0 15px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.info-content {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}
.info-content h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
}
.info-content span {
  color: black;
  font-size: 18px;
}

.icon-content-syllabus-itemC {
  color: #e74c3c;
  width: 22px;
  margin-left: 5rem;
  cursor: pointer;
}
.icon-content-syllabus-itemE {
  color: #009688;
  width: 22px;
  margin-left: 5rem;
  cursor: pointer;
}
.container .info span a {
  color: violet;
  text-decoration: none;
}
.info-content span .fa {
  color: #e74c3c;
}
.tb {
  margin-bottom: 25px;
}
.chercher{
  margin-bottom: 5%;
  margin-left: 2px;
  margin-right: 2px;

}
.row .content-block {
  width: 100%;
  overflow-y: hidden;
}

.input-group-prepend.SearchB.offset-md-5 {
  margin-bottom: 10px;
}