.component-tache-item {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 10px;
}
.titre-component-tache-item {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}
.content-component-tache-item {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
