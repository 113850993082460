
.component-ajout-examen-pratique-coach-assistant{
    
}.component-ajout-examen-pratique {
}
.component-content-ajout-exam-pratique {
}
.title-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #069a77;
}

.bg-ajout-question {
  background-color: white;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 15px;
}

.input-add-quiz {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.055em;
}

.btn-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding-bottom: 1rem;
  width: 249px;
  height: 50px;
  background: #ff9800;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.btn-ajout-question:hover {
  color: white;
}
