.component-dashboard-coach-assistant {
}
.form-input-dashboard-coach {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.6);
  height: 40px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.form-input-dashboard-coach:focus {
  border: 0.5px solid #069a77;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.modal-content-coach {
  padding: 1rem;
  border: 0;
  position: relative;
  border-radius: 0;
}
.datapicker-custom-bakeli {
  width: 100%;
}
.select-all-label-send-task-to {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #069a77;
}
.btn-icon-table {
  padding: 0;
  margin: 0 10px;
  background: transparent;
  display: flex;
  align-items: center;
}
.icon-btn-icon-table {
  color: #b1b1b1;
  transition: 0.2s ease-in-out;
}
.icon-btn-icon-table:hover {
  color: #069a77;
  transition: 0.2s ease-in-out;
}
.todo-badge {
  background-color: #f04f43;
  color: #fff;
}
.doing-badge {
  background-color: #ffc107;
  color: #fff;
}
.done-badge {
  background-color: #58b15b;
  color: #fff;
}
.badge {
  padding: 0.3em 0.6em;
}

@import url(https://fonts.googleapis.com/css?family=Muli);

.button-graphe {
  margin: 20px auto;
  display: inline-block;
  background: #ff9800;
  font-family: "Muli", Arial, sans-serif;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #009688;
  padding: 3px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.button-graphe span {
  display: block;
  padding: 0 20px;
  border: 1px solid #009688;
  line-height: 42px;
  font-size: 14px;
  border-radius: 4px;

  letter-spacing: 2px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.button-graphe :hover {
  background-color: #009688;
  transform: translate(0px, -10px) scale(1.2);
}
.button-graphe span:hover {
  border: 1px solid #fff;
  color: #fff;
}

.box {
  /* margin: 50px auto; */
  text-align: right;
}
