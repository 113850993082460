
.component-examen-theo-item-disabled-coach-assistant{
    
}.component-examen-item {
}

.component-examen-item {
}

.syllabus-item1 {
  opacity: 0.5;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 15px;
}

.container-syllabus-item-back {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.74);
  border-radius: 15px;
  padding: 2rem 2rem 3rem;
}

.container-syllabus-item {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none !important;
  display: flex;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.container-syllabus-item:hover .syllabus-item {
  /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
  opacity: 1;
}

.content-syllabus-item {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0 0 15px 15px;
  position: relative;
}
.image-syllabus-item {
  border-radius: 15px 15px 0 0;
  width: 100%;
}

.titre-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #0f0f0f;
  margin-bottom: 15px;
}
.icon-content-syllabus-item {
  color: #009688;
  width: 12px;
  margin-right: 10px;
}
.info-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(13, 12, 12, 0.8);
  display: flex;
  align-items: center;
}
.titre-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 10px;
}
.content-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.etiquette-syllabus-item {
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant {
  background: #ec0303;
}
.etiquette-syllabus-item.confirme {
  background: #069a77;
}
.position-action-container-syllabus-item-back {
  position: absolute;
  left: 15px;
  width: 100%;
  bottom: 15px;
}
.link-details-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffffc9;
  transition: 0.1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover {
  color: #ffffff;
  transition: 0.1s ease-in-out;
  text-decoration: none;
}
.icon-details-container-syllabus-item-back {
  width: 15px;
  margin-right: 8px;
}
.height-container-syllabus-item-back {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.bg-debutant {
  background: #dd4b39 !important;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-intermediaire {
  background: #f3bc07;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-avance {
  background: #069a77;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}

.transp {
  background: transparent;
}
