.numero-tache-syllabus-item-tache {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: #069a77;
}
.duree-tache-syllabus-item-tache {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.titre-syllabus-item-tache {
  font-family: "Inter", sans-serif;
}
.allTache {
  top: 62px;
}
.titre-syllabus-item-tache {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: #000000;
}
.contenu-syllabus-item-tache {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.dropdown-syllabus-item-tache {
  position: absolute;
  right: 10px;
  top: 25%;
}
.icon-dropdown-syllabus-item-tache {
  width: 30px !important;
  height: 30px !important;
}
.dropdown-syllabus-item-tache > .rs-dropdown-toggle > .rs-btn-default {
  background-color: transparent;
  width: 30px;
  height: 30px;
}

img.thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}
img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
