
.component-voir-mes-contenus{
    
}
.place-folder {
    width: 207.57px;
    height: 173.76px;
    left: 585.02px;
    /* top: 162px; */
    padding-bottom: 15px;
    margin-top: 20px;
    cursor: pointer;
  }
 .texte_centrer {
    font-size: 14px;
    font-weight: bold;
    color: #469789;
    text-decoration: underline;
}
.scroll {
    overflow: scroll;
    height: 100vh;
}
.bordure {
    border: 1px #009688 solid;
    border-radius: 1rem;
}
.nom {
    margin-left: 2rem;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.vp{
    padding-bottom: 3%;
    padding-top: 5%;
}
.archiveColor{
    color: #eb4234;
}