
.component-ajout-cv-bakeliste{

}
.chevron {
    color: whitesmoke;
    position: absolute;
    /* left: 15%; */
    /* right: 15%; */
    /* top: 70%; */
    /* bottom: -10%; */
}
.iconeVoir{
    color: #ff9800;
}
.imagePortfolio {
    width: 100%;
}
.edit-education{
    color: #397973;
    margin-left: 20%;
}
.edit-tache{
    color: #397973;
    margin-left: 20%;
    width: 15px;
}
.according-text{
background-color: #397973;
color: whitesmoke;
size: 14;
font-weight: bold;
}
.h61{
    margin-bottom: 11px;
    text-decoration: underline;
}
.accordion {
    margin-bottom: 3%;
    cursor: pointer;
}
.fmTa{
    /* vert */
border: 1px solid #069A77;
box-sizing: border-box;
/* Drop Shadow */

box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.carde{
    border:none;
}
.panel-volkeno-green .panel-footer {
    color: #009688 !important;
  }
  .panel-volkeno-orange .panel-footer {
    color: #ff9800 !important;
  }
  .panel-volkeno-red .panel-footer {
    color: #f44336 !important;
  }
.formrow{
    padding-top: 1,25rem;
}
.col-md-12.motivations_item {
    padding: 2rem;
}
h5.project_name_label {
    padding: 1rem;
}
.col-md-12.education_item_container.formrow {
    padding: 1rem;
}
.bodyAcordion{
    background-color: white;
    padding: 1rem;
    /* padding-left: 1rem; */

}
.newform{
    padding-left: 2rem;
}
.bodyAcordionPortfolio{
    background-color: white;
    padding: 1rem;
    /* padding-left: 1rem; */
}
.fmCv{
    background: #FFFFFF;
/* gris 6 */

border: 1px solid #666666;
box-sizing: border-box;
/* Drop Shadow */

box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.img.img_responsive.portfolio_img {
    width: 100%;
}

.portfolio_item_container .panel-footer {
    cursor: pointer;
    text-align: center;
    color: #009688;
}
.import-item-panel-container .panel-heading {
    background: #009688;
    text-align: center;
    padding: 20px;
    color: #fff;
}
.import-item-panel-container .panel-body {
    font-size: 16px;
    text-align: center;
}
.import-item-panel-container .panel.panel-default {
    border-color: #009688 !important;
}
.import-item-panel-container i.fas.fa-file-import {
    font-size: 35px;
}
.import-item-panel-container h3.panel-title {
    font-size: 25px;
}
.import-item-panel-container .panel-footer {
    padding: 15px;
    font-size: 31px;
    text-align: center;
    color: #009688;
    font-weight: 600;
    cursor: pointer;
}
.portfolio-card{
    padding: 2%;
}
.svg.feather.feather-eye {
    color: #f39831;
    width: 40%;
    margin-left: 3rem;
    /* font-weight: bold; */
}
.span-projet{
    text-decoration: underline;
    color:#009688 ;
    font-weight: bold;
}

.modal-90w{
width: 50vw;
}