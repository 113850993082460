.titre-syllabus-details-card{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.add-tache-syllabus{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.etiquette-syllabus-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 3px 15px;
    border-radius: 35px;
    display: inline-block;
}


.etiquette-syllabus-details.debutant{
    background: #EC0303;
}
.etiquette-syllabus-details.intermediaire{
    background: #f3bc07;
}
.etiquette-syllabus-details.avance{
    background: #069A77;
}
.icon-add{
    font-size: 20px;
    color: #069A77;
}
.btn-add-with-icon-plus-circle{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    background: transparent;

    align-items: center;
    transition: .2s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;
    height: 36px;
    width: 100%;

    font-weight: 600;   
     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);


}
.btn-add-with-icon-plus-circle:hover{
    transition: .2s ease-in-out;
    color: #069A77;
}
.content-syllabus-details-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 163.9%;
    color: #000000;
}
.navigation-previous{
    position: absolute;
    left: 15px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
}
.navigation-previous:hover{
    color: #069A77 !important;
    transition: .2s ease-in-out;
}
