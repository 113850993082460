.component-table-notes-all-bakeliste{
    
}
.t-b-color{   
    justify-content: center;
    padding: 5px;
    background: rgba(0, 150, 136, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
    display: flex;
}
.b-color{
    justify-content: center;
    display: flex;
    padding: 5px;
    background: rgba(255, 152, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
}
.p-m-color{
    justify-content: center; 
    display: flex;
    padding: 5px;
    background: rgba(244, 67, 54, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
}
.t-btn-mention{
    width: 88px;
}
.la-taille-div {
    height: 40%;
    font-size: 10px;
  }
  .title-table-notes {
    height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
      
  }
  .table-titles {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.055em;
    color: #009688;
  }
