.lire-suite-btn{
    background: #009688;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding: .5rem 2rem;
    border: 1px solid #009688;
    transition: .2s ease-in-out;
}
.lire-suite-btn:hover{
    transition: .2s ease-in-out;
    border: 1px solid transparent;
    background-color: #FFFFFF;
    color: #009688;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.ri-content{
    padding: 25px 35px 100px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 15px;
}
.ri-logo{
    width: 65px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
}
