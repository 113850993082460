.component-liste-taches-coach-view {
}

.error-text {
  color: red;
  text-align: center;
}
.inputR {
  margin-top: -7px;
  background-color: #f2faf9;
  width: 57%;
  margin-left: -6px;
  height: 96px;
}
.rechercheTache {
  border-radius: 2rem;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc;
}
/* .css-yk16xz-control {
  border-radius: 2rem !important;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc !important;
} */
/* .fixed {
  position: fixed;
  top: 88px;
  z-index: 1030;
} */
.fixer {
  position: fixed;
  top: 134px;
  z-index: 1030;
  width: 28.5%;
}
.fixe {
  position: fixed !important; 
  /* top: 200px; */
  top: 15% !important;
  z-index: 1031 !important;
  width: 20% !important;
}

.search{
  z-index: 1030;
  margin-top: -4%;
  position: fixed;
  width: auto;
}
.size{
  width: 100%;
  margin-left: -16px;
}

.syl {
  margin-top: 13%;
}
.fis {
  position: fixed;
  /* top: 200px; */
  top: 195px;
  z-index: 1030;
  width: 34%;
  margin-top: -61px;
  height: 17%;
}
.fixeS {
  position: fixed;
  top: 44px;
  z-index: 1030;
}
.color {
  background-color: #f2faf9;
}

/* .css-2b097c-container {
  width: 30%;
  z-index: 1030;

  box-sizing: border-box;
  position: fixed;
  margin-left: 32%;
  margin-top: 57px;
} */
.searchSyllabus {
  border-radius: 2rem;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc;
}
.form-control-costom {
  border: none;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.tache {
  top: 39px;
}
.form-control-costom {
  border: none;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.iconInput {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding-right: 10px;
  background-color: white;
}
.input {
  margin-left: 33rem;
}
/* 
.allTache{
  top:-5px;
} */
