.link-groupe-whatsapp{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    background: #FFFFFF;
    border: 1px solid #49DD7B;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    display: flex;
    align-items: center ;
    transition: .2s ease-in-out;
    padding: 0 1rem 0 2rem;
    justify-content: center;
    height: 45px;
    position: relative;
}
.link-groupe-whatsapp:hover{
    text-decoration: none;
    transition: .2s ease-in-out;
    background-color: #49DD7B;
    color: #FFFFFF;
}
.icon-link-groupe-whatsapp{
    width: 45px;
    border-radius: 50%;
    position: absolute;
}
.link-groupe-whatsapp-mobile {
    border-radius: 100px;
    position: relative;
}
.header-table-groupe-view{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #069A77;
}