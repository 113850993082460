
.component-syllabus-coach-inner-details{
    
}
.mto-3{
    margin-top: 3rem;
}
.titre-syllabus-details-card{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.add-tache-syllabus{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.etiquette-syllabus-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 3px 15px;
    border-radius: 35px;
    display: inline-block;
}


.etiquette-syllabus-details.debutant{
    background: #EC0303;
}
.etiquette-syllabus-details.intermediaire{
    background: #f3bc07;
}
.etiquette-syllabus-details.avance{
    background: #069A77;
}
.icon-add{
    font-size: 20px;
    color: #FFFFFF;
}
.icon-add-tache{
    font-size: 20px;
    color: #FFFFFF;
}
.btn-add-with-icon-plus-circle{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    background: transparent;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    background-color: white;
border-radius: 1rem;
height: 36px;
width: 100%;
}
.aaaaa{
    color: white;
    font-size: 17px;
    font-weight: 500;
}

.btn-add-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    border-radius: 0.6rem;

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: fit-content;
    margin-left: auto;
}
.btn-add-with-icon-plus-circle:hover{
    transition: .2s ease-in-out;
    color: #069A77;
}
.content-syllabus-details-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 163.9%;
    color: #000000;
}
.navigation-previous{
    position: absolute;
    left: 15px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
}
.navigation-previous:hover{
    color: #069A77 !important;
    transition: .2s ease-in-out;
}
