.content-nav-fil-actualite-livraison{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 15px 50px;
    position: fixed;
    width: 100%;
}
.nav-fil-actualite-livraison{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
    padding: 10px 0;
}
.nav-fil-actualite-livraison:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.nav-pills .nav-fil-actualite-livraison.active, .nav-pills .show>.nav-fil-actualite-livraison {
    color: #069A77;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.date-livraison-fil-d-actualite{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #DAF8F4;
    border-radius: 10px;
    text-align: center;
    color: #055F49;
    padding: 6px 12px;
    display: inline-flex;
    margin-bottom: 10px;
}
.content-fil-actualite{
    overflow: auto;
    height: 100vh;
}
