#assignBody {
  font-family: "Din";
}

#btn_assign {
  background-color: #f9f9f9;
  -webkit-text-fill-color: #009688;
  border-color: #d1d1d1;
  font-family: "Din";
}
.add-coach-btn-container a {
  border-radius: 50%;
}

#loader {
  position: absolute;
  left: 40%;
  z-index: 1;
  width: 100px;
  height: 100px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */
