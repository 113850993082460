.component-resultat-examen-theorique {
}

.component-content-resultat-exam-theorique {
}

.card-resultat {
  background: #fff;
}

.resultat-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.055em;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.score-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.score-number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.055em;
  color: #009688;
}
.card-resultat-two {
  background: #ffffff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.percentage {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}

.text-prochain {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.result-final {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.055em;

  /* #009688 */
  text-align: center;
  color: #009688;
}
.txt-not-final {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}
.txt-note {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}
