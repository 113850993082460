
.component-liste-tache-bakeliste{

}

.input-group1{
    position: relative;
    display: flex;
    align-items: stretch;
    width: 72%;
    background-color: white;
    border: 0.5px solid #069a77;
    box-sizing: border-box;
    border-radius: 10px;
}
.search_icon{
    color:  #069a77;
    position: absolute;
    /* left: 90px; */
    top: 8px;
}
.size1{
    width: 100%;
    /* margin-top: 41px; */
    position:absolute;
    right:1rem;
    top:50%;
    margin-left: -18px;
}
.SearchB {
    border: 0.5px solid #069a77;
    box-sizing: border-box;
    border-radius: 10px;
    padding-right: 10px;
    background-color: white;

  }
  .pt6{
      padding-top: 70px;
  }

  .pagination-container .pagination-item.selected{
    background-color: #069a77 !important;
    color: white
  }

  .pagination-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  .pagination-container .pagination-item{
        padding: 0 12px;
        height: 42px!important;
        text-align: center;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.01071em;
        border-radius: 50% !important;
        line-height: 1.43;
        font-size: 16px;
        min-width: 42px !important;
  }

  /* BARRE DE RECHERCHE */
  .form-control-costom{
    padding-left: 1.7rem!important;
    width: 100%!important;
    /* position: relative; */
  }
  .container-search{
    width: 40%!important;
    position: relative;
    padding-right: 3.1rem;
  }