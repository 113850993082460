
.component-sous-groupe-coach-assistant{
    
}.groupe-titre-on-coach-dashboard {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #069a77;
  }
  .ajouter {
    color: #069a77;
    height: 20px;
    width: 23px;
  }
  .icon-add-sgroupe {
    font-size: 20px;
    color: #ffffff;
  }
  .btn-add-sgroupe {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    border-radius: 1rem;
    height: 36px;
    background: #469789;
    width: fit-content;
  }
  .add-sgroup {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  .voir {
    color: #ffc107;
    height: 23px;
    width: 23px;
  }
  .edit {
    color: #080701;
    height: 23px;
    width: 23px;
  }
  