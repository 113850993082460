@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

.component-offlinecoach {
  font-family: "Rubik", sans-serif;
  background: linear-gradient(180deg, rgb(255, 166, 166), #fff);
  background-repeat: no-repeat;
}

.containerr {
  margin-top: 100px !important;
}

h3.h33 {
  color: #9e9e9e;
  font-size: calc(20px + 6 * ((100vw - 320px) / 680));
}

button.buttonn:focus {
  box-shadow: none !important;
  outline-width: 0;
}

.cardd {
  border-radius: 0;
  /* width: calc(475px + 10 * ((100vw - 320px) / 680)); */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);
}

.card-headerr {
  /* background-color: #f44236 !important; */
  color: #f44236;
}

img.offline {
  /* width: 180px !important; */
  width: 50% !important;
}

.btn-successs {
  border: 1px solid #009688;
  padding: 8px 20px 8px 20px !important;
  border-radius: 20px !important;
  color: #fff !important;
}

.btn-successs:corner-present {
  background: #009688;
  color: #fff !important;
  border-color: #009688 !important;
}

.btn-successs:focus {
  background: #009688 !important;
  color: #fff !important;
}

.btn-successs {
  background: #009688 !important;
}

.inner li {
  list-style-type: disc !important;
}

@media (max-width: 654px) {
  .cardd {
    width: unset;
  }
}
