.component-commments {
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ffffff;
}
a {
  color: #009688;
  text-decoration: none;
}
.blog-comment::before,
.blog-comment::after,
.blog-comment-form::before,
.blog-comment-form::after {
  content: "";
  display: table;
  clear: both;
}
.drop{
  right: 0;
}

.blog-comment {
  /* padding-left: 15%;
  padding-right: 15%; */
}

.blog-comment ul {
  list-style-type: none;
  padding: 0;
}

.blog-comment img {
  opacity: 1;
  filter: Alpha(opacity=100);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.blog-comment img.avatar {
  position: relative;
  float: left;
  margin-left: 0;
  margin-top: 0;
  width: 65px;
  height: 65px;
}

.blog-comment .post-comments {
  border-bottom: 1px solid #069a77;
  /* margin-bottom: 20px; */
  /* margin-left: 85px; */
  margin-right: 0px;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  color: #6b6e80;
  position: relative;
}

.blog-comment .meta {
  font-size: 11px;
  color: #aaaaaa;
  padding-bottom: 8px;
  margin-bottom: 10px !important;
  border-bottom: 1px dashed #eee;
}

.blog-comment ul.comments ul {
  list-style-type: none;
  padding: 0;
  margin-left: 85px;
}
p.comment {
  color: black;
  font-size: 15px;
}
.blog-comment-form {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 40px;
}

.blog-comment h3,
.blog-comment-form h3 {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.comment-reply {
  background: transparent;
  width: 1.5em;
}

small.bakeliste {
  background: #009688;
  color: white;
  padding: 5px;
}
small.coach {
  background: orange;
  color: white;
  padding: 5px;
}
