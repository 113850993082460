
.component-examen-item-question{
    
}

.component-examen-item-question{
    
}
.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}
