
.component-ajout-sous-groupe-coach-assistant{
    
}

.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}
