
.component-ajout-tache-syllabus-coach-assistant{
    
}.groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
