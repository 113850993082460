.component-add-question {
}
.component-component-question-quiz {
}

.questionnaire {
  background: #ffffff;
  border: 0.25px solid #666666;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.add-question {
  color: white;
  background: #009688;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  padding: 1rem;
}
.btn-add-exam {
  color: white;
  background: #ff9800;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #009688;
  background: #fff;
  white-space: nowrap;
  padding: 2rem;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}

.form-control1 {
  height: 90%;
}

.select {
  width: 100%;
  padding-left: 1rem;
}
.select-one {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.select-two {
  width: 8.5rem;
  padding-left: -1rem;
  padding-right: 2rem;
}

.affichage-correction {
  width: 200px;
  height: 54px;
  background: rgba(0, 150, 136, 0.5);
  border-radius: 5px;
  padding-left: 1rem;
}

.toggle-btn {
  width: 56px;
  height: 22px;
  background: #9dadd3;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s 0.1s ease-in-out;
  -moz-transition: all 0.3s 0.1s ease-in-out;
  -o-transition: all 0.3s 0.1s ease-in-out;
  transition: all 0.3s 0.1s ease-in-out;
}

.toggle-btn > .inner-circle {
  width: 13px;
  height: 13px;
  background: #009688;
  border-radius: 50%;
  -webkit-transition: all 0.3s 0.1s ease-in-out;
  -moz-transition: all 0.3s 0.1s ease-in-out;
  -o-transition: all 0.3s 0.1s ease-in-out;
  transition: all 0.3s 0.1s ease-in-out;
}

.toggle-btn.active {
  background: blue !important;
}

.toggle-btn.active > .inner-circle {
  margin-left: 50px;
}

.component-ajouter-quiz-coach {
}
.title_quizz {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ff9800;
}
.bg-add-quiz {
  background-color: white;
  padding: 3rem;
}
.input-add-quiz {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: white;
}
.btn-add-quiz {
  background: #009688;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 1rem;
}
.btn-add-quiz:hover {
  color: white;
}
.modal-quiz {
  text-align: center;
  color: #009688;
  padding: 1rem;
}
.bg-icon-modal {
  background: #edecea;
  border-radius: 50%;
}
.icon-close {
  color: red;
}
.texte_modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #069a77;
}
.btn-theme-orange {
  background: #ff9800 !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid #ff9800 !important;
}
.btn-theme-danger {
  background: red !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid red !important;
}
