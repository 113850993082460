
.component-add-livraisonsv2{
    
}



  .btn-livraison  {
    --y: -25;
    --x: 0;
    --rotation: 0;
    --speed: 2;
    /* --txt: "Show me attention"; */
    --padding: 1rem 1.25rem;
    cursor: pointer;
    padding: var(--padding);
    color: transparent;
    font-weight: bold;
    /* font-size: 1.25rem; */
    transition: background 0.1s ease;
    background: hsl(var(--hue), 100%, 50%);
  /* background: linear-gradient(#0096876e, orange); 
  background: linear-gradient(-45deg, #009688, #f65347, #ff9800, #ff9800);
    outline-color: hsl(var(--hue), 100%, 80%); */
    animation: flow-and-shake, gradient 5s ease infinite;
    animation-duration: calc(var(--speed) * 1s);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    font-family: 'Montserrat', sans-serif;

  }
  .btn-theme-gradient {
    /* background: linear-gradient(#0096876e, orange); */
    background: linear-gradient(-45deg, #009688, #f65347, #ff9800, #ff9800);
    background-size: 400% 400%;
    /* animation: gradient 5s ease infinite; */
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #069a77 !important;
  }
  .btn-theme-gradient:hover {
    color: #069a77 !important;
    background: linear-gradient(#ffff, orange);
    transition: 0.2s ease-in;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .btn-livraison :after {
    content: var(--txt);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--padding);
    color: #fff;
  }
  .btn-livraison :hover {
    background: hsl(var(--hue), 100%, 40%);
    --speed: 0.1;
    --rotation: -1;
    --y: -1;
    --x: 1;
    --txt: "Click me!";
  }
  .btn-livraison :active {
    --speed: 4;
    --x: 0;
    --y: 5;
    --rotation: 0;
    --txt: "Ahhhh...";
    background: hsl(var(--hue), 100%, 30%);
  }
  .button__wrap {
    position: relative;
  }
  .button__shadow {
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #af9d9d;
    animation: shadow 2s infinite ease-in-out;
    z-index: -1;
  }
  @-moz-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-webkit-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-o-keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @keyframes shadow {
    0%, 100% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      transform: scaleX(0.25);
    }
  }
  @-moz-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @-webkit-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @-o-keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @keyframes flow-and-shake {
    0%, 100% {
      transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  