
.component-share-to-coach-assistant{
    
}.btn-share-to{
    padding: 0;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .2s ease-in-out;
}
.btn-share-to:hover{
    background: none;
    color: #ffffff;
    transition: .2s ease-in-out;
}