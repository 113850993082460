 
  .cardPic {
    display: flex;
    background: linear-gradient(-90deg, rgb(238, 238, 238) 0%, rgb(210, 210, 210) 30%, rgb(238, 238, 238) 50%, rgb(238, 238, 238) 100%);
      background-size: auto;
    border-radius: 50%;
    /* background-size: 100% 200%; */
    animation: pulse 0.9s linear infinite forwards;
    width: 60%;
height: 60px;
    margin-left: 1rem;
    text-align: center;
  }

  .nameS{
    display: block;
    height: 10px;
    margin-top: 1rem;
margin-left: -1rem;
    width: 35%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;
  }
  .dateS{
    display: block;
    height: 10px;
    margin-top: 1rem;
    margin-left: -1rem;    width: 60%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;
  } 
.fb-grid{
    display: block;
    height: 300px;
    margin: 1rem;
    width: 95%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;
}
.commentaires{
    display: block;
    height: 40px;
    margin: 1rem;
    width: 95%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;
}
.tache{
    display: block;
    height: 10px;
    margin-top: 2rem;
    margin-left: -5rem;
    margin-bottom: 1rem;    width: 70%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;
}
.num{
    display: block;
    height: 10px;
    margin-top: 2rem;
margin-left: 5rem;
margin-bottom: 1rem;
    width: 30%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    animation: pulse 0.9s linear infinite forwards;  
}