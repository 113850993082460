.side-navbar-custom {
  background: #009688;
  padding: 0;
}
.icon-side-navbar-custom {
  width: 20px;
  margin-right: 25px;
}
.nav-link-side-navbar-custom {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
  padding: 0.7rem 2rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in;
  border-right: 5px solid transparent;
  border-radius: 0;
}

.nav-link-side-navbar-custom:hover {
  background-color: #fff !important;
  color: #009688 !important;
  border-radius: 0;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
}
.nav-link-side-navbar-custom.active {
  color: #fff !important;
  background-color: transparent !important;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
  border-radius: 0;
}
.nav-link-side-navbar-custom.active:hover {
  background-color: rgba(255, 255, 255, 0.952) !important;
  color: #009688 !important;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
  border-radius: 0;
}
.container-nav-vertical {
  background: #009688;
  padding: 3rem 0 10rem;
  position: fixed;
  max-width: 16.666667%;
  width: 100%;
  height: 100%;
}
.coach-info {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  min-height: 200px;
  width: 100%;
  background-color: orange;
  margin-top: 30px;
}
