.illustration-bsi {
    text-align: center;
    padding: 0;
    margin-bottom: 50px;
    font-size: 20px;
}
span.point-label-bsi {
    background: #009688;
    color: #fff;
    padding: 10px;
    font-weight: 600;
}
span.me-label-bsi {
    color: #009688;
    font-weight: 600;
    background: rgba(0,150,136,.3);
    padding: 10px 10px 10px 0;
}
.modal-pointage{
    text-align: center;
    padding: 2rem;
    background: #fffffffa;
    border-radius: 15px;
}
.pointage-label-bsi{
    font-size: 20px;
    color: #000000;
    font-weight: 400;
}
.heure-pointage-label-bsi{
    border-bottom: 5px double #009688;
    font-weight: 700;
}
.depart-pointage-text{
    font-size: 20px;
    font-weight: 600;
    color: #F42D2D;
    margin-bottom: 15px;
}
.depart-btn-pointage{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: .2s ease-in-out;
    background-color: #f42d2de7;
}
.depart-btn-pointage:hover{
    background-color: #F42D2D;
}

.btn-pointage{
    background: #069A77;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: .8rem 3rem;
    transition: .2s ease-in-out;
    border: 1px solid transparent;
}
.btn-pointage:hover{
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    color: #069A77;
}
.input-pointage{
    color: #000000;
    font-size: 14px;
    height: 40px;
}
.input-pointage:focus{
    border: 1px solid #069A77;
    height: 40px;
    margin-top: .3px;
}
.icon-input-pointage{
    width: 20px;
    color: #ffffff;
}