
.component-ajouter-resume{
    
}
.ht {
    height: 120%;
}

.editorClassName.rdw-editor-main {
    background-color: white;
    /* width: 114px; */
    height: 200px;
}