.component-livrable-bakeliste-item{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
}
.num-tache-livrable-bakeliste-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #069A77;
    margin-bottom: 10px;
}
.date-livrable-bakeliste-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.badge-livrable{
    font-size: 17px;
}
.separateur-livrable-bakeliste-item{
    border-left: 1px solid #C4C4C4;
    padding: 5px 15px;
}
.titre-tache-livrable-bakeliste-item{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 5px;
}

.content-tache-livrable-bakeliste-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.btn-livrable-bakeliste-item{
    border: 1px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #069A77;
    width: 100%;
    padding: .7rem 0;
    background: #ffffff;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-livrable-bakeliste-item:hover{
    background: #069A77;
    transition: .2s ease-in-out;
    color: #FFFFFF;
}
.icon-btn-livrable-bakeliste-item{
    margin-right: 10px;
    color: #EDEBE8;
    width: 15px;
    height: 15px;

}
.icon-btn-livrable-bakeliste-itemS{
    margin-right: 10px;
    color: #f96b57;
    width: 30px;
    height: 30px;
    cursor: pointer;

}
.btn-theme-red {
    background: #f96b57 !important;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #f96b57 !important;
  }
  .btn-theme-green {
    background: #f39831 !important;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #f39831 !important;
  }
  /* .btn-theme-red:hover {
    color: #f96b57 !important;
    background: #ffffff !important;
    transition: 0.2s ease-in;
  } */
  