.link-aide {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #009688;
  transition: 0.2s ease-out;
  background-color: transparent;
}
.link-aide:hover {
  text-decoration: none;
  transition: 0.2s ease-out;
  color: #01645a;
}
.item-statistics-dashboard {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}
.designation-item-statistics-dashboard {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.chiffre-item-statistics-dashboard {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 60px;
  color: #009688;
}
.sendFixe {
  position: fixed !important;
  bottom: 0 !important;
  z-index: 1030 !important;
  right: 0 !important;
}
.img-item-statistics-dashboard {
  width: 40px;
  position: absolute;
  top: 15px;
  right: 20px;
}

.img-item-statistics-dashboard.icon2 {
  height: 30px;
  width: 25px;
}

.card-dashboard {
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 5px;
}
.send {
  margin-left: 69%;
}
.h-card-dashboard-bakeliste {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
}
.icon-h-card-dashboard-bakeliste {
  width: 16px;
  margin-right: 15px;
}

@media (min-width: 767px) {
  .sendFixe {
    display: none !important;
  }
}
.info-exam {
  color: rgba(0, 0, 0, 0.94);
}
.bell {
  display: block;
  width: 40px;
  /* height: 40px; */
  font-size: 88px;
  margin: 50px auto 0;
  color: #b73c36;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.fonct-y {
  color: #0c766f;
  text-align: center;
  font-style: italic;
  font-weight: 700;
}
h5.fonct-q {
  color: orange;
  font-weight: 600;
  font-variant-caps: all-petite-caps;
}

.container-info {
  width: 340px;
  height: 420px;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fd;
}
.bg-circles div {
  aspect-ratio: 1/1;
  background-color: rgb(253, 19, 58);
  border-radius: 50%;
  position: absolute;
}

#cercle-1 {
  width: 70%;
  left: -20%;
  top: -80%;
  opacity: 0.5;
}
#circle-2 {
  width: 120%;
  left: -20%;
  top: -80%;
  opacity: 0.4;
}
#circle-1 {
  width: 120%;
  left: -20%;
  top: -80%;
  opacity: 0.4;
}

#title {
  margin-top: 35%;
  text-align: center;
  font-size: 1.6rem;
  color: #1b1b1b;
}

@keyframes breathing {
  0%,
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(1);
    transform: scale(0.9);
  }
}

.conf-text {
  margin-top: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: #606060;
}
