.component-table-my-bakelistes {
}

.character-bullets-3 {
  list-style: square;
  cursor: default;
}

.character-bullets-2 li::before {
  content: "👎";
  margin: 0px 20px 0 0px;
}

.character-bullets-3 li::before {
  /* content: "📚"; */
  /* margin: 0px 20px 0 0px; */
  /* color: pink; */
  font-size: 30px;
}

.cursor {
  cursor: pointer;
}
