.li-save-reunion-dashboard-bakeliste{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(1, 31, 59, 0.75);
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
}
.icon-li-save-reunion-dashboard-bakeliste{
    width: 14px;
    color: #009688;
    margin-left: auto;
}