
.component-contenus-coach{
    
}

article {
  height: 100%;
}

.image {
  background-image: url("https://images.unsplash.com/photo-1516571137133-1be29e37143a?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=98feb08eaacddba8c029c8b5298ca3a0");
  background-size: cover;
}

.headline {
  background-color: #009688;
  color: #e8ac52;
  padding: 0.75rem;
  text-align: center;
  box-shadow: 0 1.2em 0 0.75em;
}

.headline p {
  font-size: 1.25em;
}
.headline h1 {
  margin-top: -0.25em;
  margin-bottom: 0.5em;
  font-size: 3em;
  text-transform: uppercase;
  line-height: 1;
  text-shadow: 3px 3px 0px #009688;
}

.byline {
  margin: 0 1rem 1rem 1rem;
  font-family: "Libre Baskerville", serif;
  font-style: italic;
  letter-spacing: -0.2px;
}

.article-text {
  margin: -1em auto;
  padding: 0.5em 1em;
  max-width: 620px;
  font-size: 1em;
  line-height: 1.25;
  color: #3e2723;
}

.first::first-letter {
  float: left;
  margin: 0 0.2em 0 0;
  color: 009688;
  font-size: 4em;
  font-family: "Libre Baskerville", serif;
  font-weight: 600;
  text-shadow: 2px 2px 0px #009688;
}

/* CSS Grid starts here! */

.article-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 2fr 1fr 1fr 1fr;
}

.image {
  grid-column: 1 / -1;
  grid-row: 1 / 3;
}
.headline {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  z-index: 100;
}
