.component-table-my-bakelistes-coach-assistant {
}
.component-table-my-bakelistes {
}

.character-bullets-3 {
  list-style: square;
  cursor: default;
}

.character-bullets-2 li::before {
  content: "👎";
  margin: 0px 20px 0 0px;
}
.close-modal-bakeliste-title1 {
  color: #f33c23;
  padding: 0;
  background: transparent;
  position: absolute;
  right: 5px;
  cursor: pointer;
  /* margin: 0px 20px 0 0px; */
  /* color: pink; */
  /* font-size: 19px; */
}

.cursor {
  cursor: pointer;
}

.orange-color {
  color: #ff9800;
}

.red-color {
  color: #f34336;
}

.flex-row {
  display: flex;
}
