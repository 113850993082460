.component-modal-contact-us{
    margin-left: auto;
}
.modal-contact-us{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7) !important;
}
.input-contact-us{
    border: 0.5px solid #797878;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #282828;
    height: 50px;
}
.input-contact-us:focus{
    border: 1px solid #069A77;
    outline: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}