
.component-note-examen{
    
}
.type-input-search{
    border: 1px solid #009688;
    box-sizing: border-box;
    border-radius: 10px;
}
.type-input-search:focus{
    border: 1px solid #009688;
}
.has-search{
    padding-right: 30%;
    margin-right: 15px;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-left: 45%;
}
.format-txt-h4-examen{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.055em;
    color: #009688;
}
.btn-export-to{
    background: #FF9800;
    border-radius: 10px;
    height: 38px;
    color: #fff;
}
