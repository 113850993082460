

.component-liste-des-bakeliste-for-exam{
    
}
.liste-bakeliste-exam {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.055em;
    color: #000000;

}
.imglistebakeliste {
    width: 42px;
    height: 42px;
}
.liste-costom {
    list-style-type: none;
    text-decoration: none;
    border: none;
    white-space: nowrap;
}
.nom-bakeliste {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.055em;
    color: rgba(0, 0, 0, 0.8);
}
