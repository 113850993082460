.component-livraison-coach {
}

.parentDisable {
  position: fixed;

  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}
.input-group1{
  position: relative;
  display: flex;
  align-items: stretch;
  width: 72%;
  background-color: white;
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
}
.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 1000;
  position: fixed;
}
