.component-faire-examen {
}

.component-content-examen-theorique {
}

.nav-theorique {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #009688;
  border: none;
}
.nav-theorique:active {
  border: none;
}

.card-exam {
  height: 100%;
  border: none;
  border-radius: 2px;
  background: #fff;
}

.partie-theorique {
  background: #009688;
  padding-top: 2rem;
  padding-left: 2rem;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.part-article {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}
.part-article h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-article-par p {
  width: 279px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.part-article1 {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}

.part-article1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-two-title {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.exam-question-one {
  width: 98%;
  height: 66px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.exam-question-one p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.exam-btn-next {
  padding-left: 60%;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.exam-btn {
  color: #ffffff;
  width: 314.17px;
  height: 50px;
  left: 775px;
  top: 813px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.exam-btn:hover {
  color: white;
  text-decoration: none;
}

/* button radio */
.radio-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-row:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: #009688;
  box-shadow: 2px 2px 10px 2px rgb(255, 152, 0);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-row:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  color: #009688;
  background-color: #ffff;
  box-shadow: 2px 2px 20px 2px rgb(255, 152, 0);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-wrapper {
  margin: 0px 4px;
}

.radio-btn {
  background-color: #ff9800;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.055em;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  color: #000000;
  padding: 1rem;
}

.radio-btn-selected {
  background-color: #ffffff;
  border: 1px solid #55bc7e;
  color: #55bc7e;
  font-size: 14px;
  line-height: 26px;
  outline: none;
}
.libelle-reponse {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 62px;
  letter-spacing: 0.055em;
}
