.component-table-bakeliste {
}

.label-color-green {
  color: #069a77;
  font-weight: 500;
}

.formation-courte {
  background-color: #069a77;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px;
} 

.formation-longue {
  background-color: orange;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px;
}

.training-time {
  background-color: #b5915c;
  color: white;
  padding: 5px;
}
