.card-status-info {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  margin-bottom: 5px;
}
.status-indicator-a-faire {
  border: 1px solid #db4437;
  background-color: #db4437;
}
.status-indicator-en-cours {
  border: 1px solid #ffae33;
  background-color: #ffae33;
}
.status-indicator-termine {
  background: #069a77;
  border: 2px solid #069a77;
}
.text-status-indicator {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.75) !important;
  flex-direction: column;
  margin: 0 7px;
}
.tache-bakeliste-limit-hours {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  color: #085550;
}
.icon-tache-bakeliste-limit-hours {
  width: 14px;
  color: #069a77;
  margin-right: 15px;
}
.content-tache-bakeliste-view {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.lien-utile-bakeliste-tache-card {
  font-size: 14px;
  display: block;
  line-height: 1.3;
  margin-bottom: 5px;
}

input[type="radio"].status-indicator.a-faire:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;

  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #db4437;
}

input[type="radio"].status-indicator.a-faire:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #db4437;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.en-cours:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #ffae33;
}

input[type="radio"].status-indicator.en-cours:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #ffae33;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.termine:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #069a77;
}

input[type="radio"].status-indicator.termine:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #069a77;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.page-item.active .page-link {
  z-index: 3; 
  color: #fff;
  background-color: #069a77;
  border-color: #069a77;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #069a77;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
