@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli);
@import url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-97/variables.less);
@import url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/mixins.less);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner-connexion-page {
  width: 100%;
  background: linear-gradient(
      90deg,
      rgba(6, 154, 119, 0.7962535355939251) 35%,
      rgba(6, 154, 119, 0.8018557764902836) 100%
    ),
    url(/static/media/banner1.69b3304f.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* padding: 4rem 8rem; */
}
.logo-connexion {
  width: 115px;
  margin-bottom: 90px;
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}
.titre-banner-connexion {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  line-height: 99.5%;
  color: #ffffff;
}
.slogan-banner-connexion {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 102%;
  letter-spacing: 0.12em;
  color: #ffffff;
}
.btn-connexion {
  background: #069a77;
  border-radius: 10px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #ffffff;
  padding: 15px;
  border: 1px solid #069a77;
  transition: 0.2s ease-in-out;
}
.btn-connexion:hover {
  background-color: #fff;
  color: #069a77;
  transition: 0.2s ease-in-out;
}

.btn-connexion-facebook:hover {
  background: rgb(83, 153, 245);
  color: #ffffff;
}
.btn-connexion-facebook {
  border-radius: 10px;
  /* width: 100%; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(83, 153, 245);
  border: 1px solid rgb(83, 153, 245);

  transition: 0.2s ease-in-out;
}
.btn-connexion-google:hover {
  background: rgb(218, 50, 50);

  color: #ffffff;
}
.btn-connexion-google {
  border-radius: 10px;
  /* width: 100%; */
  padding: 10px;
  transition: 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 17px;
  letter-spacing: 0.1em;
  background-color: #fff;
  color: rgb(218, 50, 50);
  border: 1px solid rgb(218, 50, 50);

  transition: 0.2s ease-in-out;
}
.mdp-forget-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #069a77;
  transition: 0.2s ease-in-out;
  margin-bottom: 30px;
  text-align: right;
  display: block;
}
.mdp-forget-link:hover {
  transition: 0.2s ease-in-out;
  color: #069a77;
}
.h-connexion-form {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1em;
  color: #069a77;
  margin-bottom: 50px;
  text-align: center;
}
.icon-form-connexion {
  width: 15px;
}

.input-form-connexion {
  border-top: 1px solid #069a77;
  /* border-left: 0px; */
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.51);
  height: 50px;
}
.input-form-connexion:focus {
  border-top: 1px solid #069a77;
  border-left: 0px;
  border-right: 1px solid #069a77;
  border-bottom: 1px solid #069a77;
}
.input-group-text-connexion {
  background: transparent;
  color: #069a77;
  border: 1px solid #069a77;
  border-radius: 10px;
  height: 50px;
}
.loader {
  color: #069a77;
  background-color: #069a77;
  box-shadow: 0 0.125rem 0.8rem rgba(0, 0, 0, 0.1) !important;
}

/* Social Login */

.omb_loginOr {
  position: relative;
  font-size: 1em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.omb_loginOr .omb_hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.omb_loginOr .omb_spanOr {
  display: block;
  position: absolute;
  left: 37%;
  top: -0.6em;
  margin-left: -1.5em;
  background-color: white;
  width: 10em;
  text-align: center;
}
.icon-facebook-connexion {
  width: 30px;
}

.auth-error.text-center {
  color: red;
  padding: 0.5em;
}

.kep-login-facebook {
  font-family: Helvetica, sans-serif;
  font-weight: 600;
  text-align: left;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #3b5998 !important;
  border: calc(0.06887vw + 0.67769px) solid #fff !important;
  padding: none;
  padding: calc(0.34435vw + 7.38843px) calc(0.34435vw + 8.38843px) !important;
  border-radius: 50% !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1), 0 7px 20px 0 rgba(0, 0, 0, 0.1);
}
.kep-login-facebook.metro {
  height: 50px;
  width: 100%;
  border-radius: 10px !important;
}
.kep-login-facebook.metro:hover {
  border: 2px solid #3b5998 !important;
  background-color: #fff !important;
  color: #3b5998 !important;
}

.side-navbar-custom {
  background: #009688;
  padding: 0;
}
.icon-side-navbar-custom {
  width: 20px;
  margin-right: 25px;
}
.nav-link-side-navbar-custom {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
  padding: 0.7rem 2rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in;
  border-right: 5px solid transparent;
  border-radius: 0;
}

.nav-link-side-navbar-custom:hover {
  background-color: #fff !important;
  color: #009688 !important;
  border-radius: 0;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
}
.nav-link-side-navbar-custom.active {
  color: #fff !important;
  background-color: transparent !important;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
  border-radius: 0;
}
.nav-link-side-navbar-custom.active:hover {
  background-color: rgba(255, 255, 255, 0.952) !important;
  color: #009688 !important;
  transition: 0.2s ease-in;
  border-right: 5px solid #ff9800;
  border-radius: 0;
}
.container-nav-vertical {
  background: #009688;
  padding: 3rem 0 10rem;
  position: fixed;
  max-width: 16.666667%;
  width: 100%;
  height: 100%;
}
.coach-info {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  min-height: 200px;
  width: 100%;
  background-color: orange;
  margin-top: 30px;
}

.logo-header{
    width: 80px;
}
.avatar-rs-custom{
    margin-right: 15px;
    background: rgb(6, 154, 119);
}
.welcome-header{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.055em;
    color: #069A77;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	/* background-color:#25d366;
	color:#FFF; */
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
	margin-top:16px;

}

.top-of-site-link {
    visibility: hidden;
    opacity: 0;
    transition: .2s;
    position: fixed;
    bottom: 1.2rem;
    right: 1.5rem;
    z-index: 15;
}
.my-float{
	margin-top:16px;
}


.annee-header{
    border-left: 2px solid #C4C4C4;
    margin-left: 15px;
    padding-left: 15px;
    margin-top: 0;
    display: flex;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.07em;
    color: #035540;
}
.name-avatar-custom{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #011F3B;
}
.navbar-light .navbar-toggler {
    border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(/static/media/menu.9ce43a8a.svg);
}
.height-header{
    /* height: 100px; */
}
.icon-pointage{
    width: 20px;
    margin-right: 15px;
}

.dropdown-item-header-dashboard{
    display: flex;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0;
    text-decoration: none;
    transition: .2s ease-in-out;
    color: #858585;
}
.dropdown-item-header-dashboard:hover{
    transition: .2s ease-in-out;
    text-decoration: none;
    background-color: #069A77;
    color: #ffffff;
}
.illustration-bsi {
    text-align: center;
    padding: 0;
    margin-bottom: 50px;
    font-size: 20px;
}
span.point-label-bsi {
    background: #009688;
    color: #fff;
    padding: 10px;
    font-weight: 600;
}
span.me-label-bsi {
    color: #009688;
    font-weight: 600;
    background: rgba(0,150,136,.3);
    padding: 10px 10px 10px 0;
}
.modal-pointage{
    text-align: center;
    padding: 2rem;
    background: #fffffffa;
    border-radius: 15px;
}
.pointage-label-bsi{
    font-size: 20px;
    color: #000000;
    font-weight: 400;
}
.heure-pointage-label-bsi{
    border-bottom: 5px double #009688;
    font-weight: 700;
}
.depart-pointage-text{
    font-size: 20px;
    font-weight: 600;
    color: #F42D2D;
    margin-bottom: 15px;
}
.depart-btn-pointage{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: .2s ease-in-out;
    background-color: #f42d2de7;
}
.depart-btn-pointage:hover{
    background-color: #F42D2D;
}

.btn-pointage{
    background: #069A77;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: .8rem 3rem;
    transition: .2s ease-in-out;
    border: 1px solid transparent;
}
.btn-pointage:hover{
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    color: #069A77;
}
.input-pointage{
    color: #000000;
    font-size: 14px;
    height: 40px;
}
.input-pointage:focus{
    border: 1px solid #069A77;
    height: 40px;
    margin-top: .3px;
}
.icon-input-pointage{
    width: 20px;
    color: #ffffff;
}

.component-envoyer-mon-travail{
    
}

.component-add-livraisonsv2{
    
}



  .btn-livraison  {
    --y: -25;
    --x: 0;
    --rotation: 0;
    --speed: 2;
    /* --txt: "Show me attention"; */
    --padding: 1rem 1.25rem;
    cursor: pointer;
    padding: var(--padding);
    color: transparent;
    font-weight: bold;
    /* font-size: 1.25rem; */
    transition: background 0.1s ease;
    background: hsl(var(--hue), 100%, 50%);
  /* background: linear-gradient(#0096876e, orange); 
  background: linear-gradient(-45deg, #009688, #f65347, #ff9800, #ff9800);
    outline-color: hsl(var(--hue), 100%, 80%); */
    -webkit-animation: flow-and-shake, gradient 5s ease infinite;
            animation: flow-and-shake, gradient 5s ease infinite;
    -webkit-animation-duration: calc(var(--speed) * 1s);
            animation-duration: calc(var(--speed) * 1s);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    font-family: 'Montserrat', sans-serif;

  }
  .btn-theme-gradient {
    /* background: linear-gradient(#0096876e, orange); */
    background: linear-gradient(-45deg, #009688, #f65347, #ff9800, #ff9800);
    background-size: 400% 400%;
    /* animation: gradient 5s ease infinite; */
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #069a77 !important;
  }
  .btn-theme-gradient:hover {
    color: #069a77 !important;
    background: linear-gradient(#ffff, orange);
    transition: 0.2s ease-in;
  }
  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .btn-livraison :after {
    content: var(--txt);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--padding);
    color: #fff;
  }
  .btn-livraison :hover {
    background: hsl(var(--hue), 100%, 40%);
    --speed: 0.1;
    --rotation: -1;
    --y: -1;
    --x: 1;
    --txt: "Click me!";
  }
  .btn-livraison :active {
    --speed: 4;
    --x: 0;
    --y: 5;
    --rotation: 0;
    --txt: "Ahhhh...";
    background: hsl(var(--hue), 100%, 30%);
  }
  .button__wrap {
    position: relative;
  }
  .button__shadow {
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #af9d9d;
    -webkit-animation: shadow 2s infinite ease-in-out;
            animation: shadow 2s infinite ease-in-out;
    z-index: -1;
  }
  @-webkit-keyframes shadow {
    0%, 100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      -webkit-transform: scaleX(0.25);
              transform: scaleX(0.25);
    }
  }
  @keyframes shadow {
    0%, 100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
    50% {
      opacity: 0.2;
      -webkit-transform: scaleX(0.25);
              transform: scaleX(0.25);
    }
  }
  @-webkit-keyframes flow-and-shake {
    0%, 100% {
      -webkit-transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
              transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      -webkit-transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
              transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  @keyframes flow-and-shake {
    0%, 100% {
      -webkit-transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
              transform: translate(calc(var(--x) * -1%), 0) rotate(calc(var(--rotation) * -1deg));
    }
    50% {
      -webkit-transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
              transform: translate(calc(var(--x) * 1%), calc(var(--y) * 1%)) rotate(calc(var(--rotation) * 1deg));
    }
  }
  

.component-nav-vertical{
    
}
.link-aide {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #009688;
  transition: 0.2s ease-out;
  background-color: transparent;
}
.link-aide:hover {
  text-decoration: none;
  transition: 0.2s ease-out;
  color: #01645a;
}
.item-statistics-dashboard {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}
.designation-item-statistics-dashboard {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.chiffre-item-statistics-dashboard {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 60px;
  color: #009688;
}
.sendFixe {
  position: fixed !important;
  bottom: 0 !important;
  z-index: 1030 !important;
  right: 0 !important;
}
.img-item-statistics-dashboard {
  width: 40px;
  position: absolute;
  top: 15px;
  right: 20px;
}

.img-item-statistics-dashboard.icon2 {
  height: 30px;
  width: 25px;
}

.card-dashboard {
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 5px;
}
.send {
  margin-left: 69%;
}
.h-card-dashboard-bakeliste {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
}
.icon-h-card-dashboard-bakeliste {
  width: 16px;
  margin-right: 15px;
}

@media (min-width: 767px) {
  .sendFixe {
    display: none !important;
  }
}
.info-exam {
  color: rgba(0, 0, 0, 0.94);
}
.bell {
  display: block;
  width: 40px;
  /* height: 40px; */
  font-size: 88px;
  margin: 50px auto 0;
  color: #b73c36;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  1% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  3% {
    -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg);
  }
  5% {
    -webkit-transform: rotate(34deg);
            transform: rotate(34deg);
  }
  7% {
    -webkit-transform: rotate(-32deg);
            transform: rotate(-32deg);
  }
  9% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  11% {
    -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg);
  }
  13% {
    -webkit-transform: rotate(26deg);
            transform: rotate(26deg);
  }
  15% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg);
  }
  17% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  19% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  21% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  23% {
    -webkit-transform: rotate(-16deg);
            transform: rotate(-16deg);
  }
  25% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }
  27% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  29% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  31% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  33% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }
  35% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  37% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  39% {
    -webkit-transform: rotate(-1deg);
            transform: rotate(-1deg);
  }
  41% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
  }

  43% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.fonct-y {
  color: #0c766f;
  text-align: center;
  font-style: italic;
  font-weight: 700;
}
h5.fonct-q {
  color: orange;
  font-weight: 600;
  -webkit-font-feature-settings: "pcap", "c2pc";
          font-feature-settings: "pcap", "c2pc";
  font-variant-caps: all-petite-caps;
}

.container-info {
  width: 340px;
  height: 420px;
  padding: 2rem;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fd;
}
.bg-circles div {
  aspect-ratio: 1/1;
  background-color: rgb(253, 19, 58);
  border-radius: 50%;
  position: absolute;
}

#cercle-1 {
  width: 70%;
  left: -20%;
  top: -80%;
  opacity: 0.5;
}
#circle-2 {
  width: 120%;
  left: -20%;
  top: -80%;
  opacity: 0.4;
}
#circle-1 {
  width: 120%;
  left: -20%;
  top: -80%;
  opacity: 0.4;
}

#title {
  margin-top: 35%;
  text-align: center;
  font-size: 1.6rem;
  color: #1b1b1b;
}

@-webkit-keyframes breathing {
  0%,
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(0.9);
  }
  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0%,
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(0.9);
  }
  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.conf-text {
  margin-top: 1.5rem;
  line-height: 130%;
  text-align: center;
  color: #606060;
}

.avatar-coach-dashboard-bakeliste{
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border-radius: 50%;
}
.item-coach-dashboard-bakeliste{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px 15px 15px 7px;
    display: flex;
    width: 100%;
}
.item-coach-dashboard-bakeliste1 {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px 15px 15px 23px;
    display: flex;
    width: 100%;
}
.name-coach-dashboard-bakeliste{
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #069A77;
}
.function-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
}
.show-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #069A77;
    transition: .2s ease-in-out;
    background-color: transparent;
    padding: 0;
    border: 0;
}
.modal-details-coach{
    padding: 2rem 3rem 6rem;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
}
.avatar-details-coach-dashboard-bakeliste{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
.name-details-coach-dashboard-bakeliste{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
.email-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
}
.h-info-details-coach-dashboard-bakeliste{
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
}
.info-details-coach-dashboard-bakeliste{
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
}


.component-bakeliste-group-item{
    
}

.lgr{
    cursor: pointer;
}

.component-statistics-bakeliste {
    
}


.component-modal-contact-us{
    margin-left: auto;
}
.modal-contact-us{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7) !important;
}
.input-contact-us{
    border: 0.5px solid #797878;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #282828;
    height: 50px;
}
.input-contact-us:focus{
    border: 1px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.content-nav-fil-actualite-livraison{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 15px 50px;
    position: fixed;
    width: 100%;
}
.nav-fil-actualite-livraison{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
    padding: 10px 0;
}
.nav-fil-actualite-livraison:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.nav-pills .nav-fil-actualite-livraison.active, .nav-pills .show>.nav-fil-actualite-livraison {
    color: #069A77;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.date-livraison-fil-d-actualite{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #DAF8F4;
    border-radius: 10px;
    text-align: center;
    color: #055F49;
    padding: 6px 12px;
    display: inline-flex;
    margin-bottom: 10px;
}
.content-fil-actualite{
    overflow: auto;
    height: 100vh;
}


.component-livraison-coach{
    
}

.hello{
    height: auto;
    overflow: auto;
   padding-left: 15px;

}

.h-theme{
    padding: inherit;
}
.scroll {
    overflow: scroll;
    height: 100vh;
}





.btn-like-fil-actualite-card {
  right: 15px;
  background: transparent;
  color: #e0e0e0;
  transition: 0.2s ease-out;
}
.btn-like-fil-actualite-card:hover {
  color: #055f49;
  transition: 0.2s ease-in;
}
.icon-btn-like-fil-actualite-card {
  width: 18px;
}
.card-fil-actualite {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.avatar-fil-actualite {
  width: 50px;
  margin-right: 10px;
}

.name-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 10px;
}

.heure-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.8);
}
.icon-comment-fil-actualite {
  width: 16px;
}
.input-comment-fil-actualite {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  width: 100%;
  height: 35px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 40px !important;
  padding-right: 60px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-send-comment-fil-actualite {
  /* position: absolute;
  z-index: 9999; */
  right: 15px;
  top: 5px;
  padding: 0;
  background: transparent;
}
.icon-btn-send-comment-fil-actualite {
  width: 22px;
}

/* Gallery */

.ReactGridGallery {
  display: flow-root;
  flex-direction: column;
  width: 100%;
  /* background: orange; */
}
.ReactGridGallery > .ReactGridGallery_tile {
  width: -webkit-max-content;
  width: max-content;
  /* border: 1px solid red; */
  float: left !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #009688;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 80vh;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 85vh;
  flex-direction: column;
  flex-flow: column;
}

.component-commments {
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ffffff;
}
a {
  color: #009688;
  text-decoration: none;
}
.blog-comment::before,
.blog-comment::after,
.blog-comment-form::before,
.blog-comment-form::after {
  content: "";
  display: table;
  clear: both;
}
.drop{
  right: 0;
}

.blog-comment {
  /* padding-left: 15%;
  padding-right: 15%; */
}

.blog-comment ul {
  list-style-type: none;
  padding: 0;
}

.blog-comment img {
  opacity: 1;
  filter: Alpha(opacity=100);
  border-radius: 4px;
}

.blog-comment img.avatar {
  position: relative;
  float: left;
  margin-left: 0;
  margin-top: 0;
  width: 65px;
  height: 65px;
}

.blog-comment .post-comments {
  border-bottom: 1px solid #069a77;
  /* margin-bottom: 20px; */
  /* margin-left: 85px; */
  margin-right: 0px;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
  background: #ffffff;
  color: #6b6e80;
  position: relative;
}

.blog-comment .meta {
  font-size: 11px;
  color: #aaaaaa;
  padding-bottom: 8px;
  margin-bottom: 10px !important;
  border-bottom: 1px dashed #eee;
}

.blog-comment ul.comments ul {
  list-style-type: none;
  padding: 0;
  margin-left: 85px;
}
p.comment {
  color: black;
  font-size: 15px;
}
.blog-comment-form {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 40px;
}

.blog-comment h3,
.blog-comment-form h3 {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.comment-reply {
  background: transparent;
  width: 1.5em;
}

small.bakeliste {
  background: #009688;
  color: white;
  padding: 5px;
}
small.coach {
  background: orange;
  color: white;
  padding: 5px;
}

.component-preloader {
}
/* 20. preloader */
.preloader {
  /* background-color: #f7f7f7; */
  width: 100%;
  height: 100%;
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  transition: 0.6s;
  margin: 0 auto;
}
.preloader .preloader-circle {
  width: 200px;
  height: 200px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: #009688;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  background-color: #fff;
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  transition: 0.6s;
}
.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}
.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  transition: 0.3s;
}
.preloader .preloader-img img {
  max-width: 100px;
}
.preloader .pere-text strong {
  font-weight: 800;
  color: #dca73a;
  text-transform: uppercase;
}
@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transition: 0.6s;
  }
}
@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.6s;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transition: 0.6s;
  }
}

div#preloader-active {
  margin: 14em 0 15em 0;
}


.component-list-coach-assistant{
    
}

.component-livraisons-itemv2{
    
}

.component-commentsv2{
    
}

.pull-right{
    float:right;
  }
  .pull-left{
    float:left;
  }
  #fbcomment{
    background:#fff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    color: #4b4f56;
    padding:50px;
  }
  .header_comment{
      font-size: 14px;
      overflow: hidden;
      border-bottom: 1px solid #e9ebee;
      line-height: 25px;
      margin-bottom: 24px;
      padding: 10px 0;
  }
  .sort_title{
    color: #4b4f56;
  }
  .sort_by{
    background-color: #f5f6f7;
    color: #4b4f56;
    line-height: 22px;
    cursor: pointer;
    vertical-align: top;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
    padding: 4px;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #ccd0d5;
  }
  .count_comment{
    font-weight: 600;
  }
  .body_comment{
      padding: 0 8px;
      font-size: 14px;
      display: block;
      line-height: 25px;
      word-break: break-word;
  }
  .avatar_comment{
    display: block;
  }
  .avatar_comment img{
    height: 48px;
    width: 48px;
  }
  .box_comment{
      display: block;
      /* position: relative; */
      line-height: 1.358;
      word-break: break-word;
      border: 1px solid #d3d6db;
      word-wrap: break-word;
      background: #fff;
      box-sizing: border-box;
      cursor: text;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 16px;
      padding: 0;
  }
  .box_comment textarea{
      min-height: 40px;
      padding: 12px 8px;
      width: 100%;
      border: none;
      resize: none;
  }
  .box_comment textarea:focus{
    outline: none !important;
  }
  .box_comment .box_post{
      border-top: 1px solid #d3d6db;
      background: #f5f6f7;
      padding: 8px;
      display: block;
      overflow: hidden;
  }
  .box_comment label{
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    color: #90949c;
    line-height: 22px;
  }
  .box_comment button{
    margin-left:8px;
    background-color: #019688;
    border: 1px solid #019688;
    color: #fff;
    text-decoration: none;
    line-height: 22px;
    padding: 2px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: bold;
    /* position: relative; */
    text-align: center;
  }
  .box_comment button:hover{
    color: #019688;
    background-color: #fff;
    border-color: #019688;
  }
  .box_comment .cancel{
      margin-left:8px;
      background-color: #f5f6f7;
      color: #4b4f56;
      text-decoration: none;
      line-height: 22px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      text-align: center;
    border-color: #ccd0d5;
  }
  .box_comment .cancel:hover{
      background-color: #d0d0d0;
      border-color: #ccd0d5;
  }
  .box_comment img{
    height:16px;
    width:16px;
  }
  .box_result{
    margin-top: 24px;
  }
  .box_result .result_comment h4{
    font-weight: 600;
    white-space: nowrap;
    color: #019688;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.358;
    margin:0;
  }
  .box_result .result_comment{
    display:block;
    overflow:hidden;
    padding: 0;
  }
  .child_replay{
      border-left: 1px dotted #d3d6db;
      margin-top: 12px;
      list-style: none;
      padding:0 0 0 8px
  }
  .reply_comment{
      margin:12px 0;
  }
  .box_result .result_comment p{
    margin: 4px 0;
    text-align:justify;
  }
  .box_result .result_comment .tools_comment{
    font-size: 12px;
    line-height: 1.358;
  }
  .box_result .result_comment .tools_comment a{
    color: #019688;
    cursor: pointer;
    text-decoration: none;
  }
  .box_result .result_comment .tools_comment span{
    color: #90949c;
  }
  .body_comment .show_more{
    background: #019688;
    border: none;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
    margin-top: 24px;
    padding: 12px;
    text-shadow: none;
    width: 100%;
    font-weight:bold;
    position: relative;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
  }
.btn-like-fil-actualite-card {
  right: 15px;
  background: transparent;
  color: #e0e0e0;
  transition: 0.2s ease-out;
}
.btn-like-fil-actualite-card:hover {
  color: #055f49;
  transition: 0.2s ease-in;
}
.icon-btn-like-fil-actualite-card {
  width: 18px;
}
.pComment {
  color: #dc3545;
  font-size: 16px;
  font-weight: 700;
}
.card-fil-actualite {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.avatar-fil-actualite {
  width: 50px;
  margin-right: 10px;
}
.name-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 10px;
}
.heure-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.8);
}
.icon-comment-fil-actualite {
  width: 16px;
}
.input-comment-fil-actualite {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  height: 35px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 40px !important;
  padding-right: 60px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-send-comment-fil-actualite {
  /* position: absolute;
  z-index: 9999; */
  right: 15px;
  top: 5px;
  padding: 0;
  background: transparent;
}
.icon-btn-send-comment-fil-actualite {
  width: 22px;
}

/* Gallery */

.ReactGridGallery {
  display: flow-root;
  flex-direction: column;
  width: 100%;
  /* background: orange; */
}
.ReactGridGallery > .ReactGridGallery_tile {
  width: -webkit-max-content;
  width: max-content;
  /* border: 1px solid red; */
  float: left !important;
}


.component-livraisons-itemv2-view{
    
}
 
  .cardPic {
    display: flex;
    background: linear-gradient(-90deg, rgb(238, 238, 238) 0%, rgb(210, 210, 210) 30%, rgb(238, 238, 238) 50%, rgb(238, 238, 238) 100%);
      background-size: auto;
    border-radius: 50%;
    /* background-size: 100% 200%; */
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
    width: 60%;
height: 60px;
    margin-left: 1rem;
    text-align: center;
  }

  .nameS{
    display: block;
    height: 10px;
    margin-top: 1rem;
margin-left: -1rem;
    width: 35%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
  }
  .dateS{
    display: block;
    height: 10px;
    margin-top: 1rem;
    margin-left: -1rem;    width: 60%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
  } 
.fb-grid{
    display: block;
    height: 300px;
    margin: 1rem;
    width: 95%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
}
.commentaires{
    display: block;
    height: 40px;
    margin: 1rem;
    width: 95%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
}
.tache{
    display: block;
    height: 10px;
    margin-top: 2rem;
    margin-left: -5rem;
    margin-bottom: 1rem;    width: 70%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;
}
.num{
    display: block;
    height: 10px;
    margin-top: 2rem;
margin-left: 5rem;
margin-bottom: 1rem;
    width: 30%;
    background: linear-gradient(-90deg,
     rgb(238, 238, 238) 0%,
     rgb(210, 210, 210) 30%,
     rgb(238, 238, 238) 50%,
     rgb(238, 238, 238) 100%);
    border-radius: 4px;
    background-size: 400% 400%;
    -webkit-animation: pulse 0.9s linear infinite forwards;
            animation: pulse 0.9s linear infinite forwards;  
}
/* .card-status-info {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  margin-bottom: 5px;
}
.status-indicator.a-faire {
  border: 1px solid #db4437;
}
.status-indicator.en-cours {
  border: 1px solid #ffae33;
}
.status-indicator.termine {
  background: #069a77;
  border: 2px solid #069a77;
}
.text-status-indicator {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.75) !important;
  flex-direction: column;
  margin: 0 7px;
}
.tache-bakeliste-limit-hours {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  color: #085550;
}
.icon-tache-bakeliste-limit-hours {
  width: 14px;
  color: #069a77;
  margin-right: 15px;
}
.content-tache-bakeliste-view {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.lien-utile-bakeliste-tache-card {
  font-size: 14px;
  display: block;
  line-height: 1.3;
  margin-bottom: 5px;
}

input[type="radio"].status-indicator.a-faire:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;

  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #db4437;
}

input[type="radio"].status-indicator.a-faire:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #db4437;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.en-cours:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #ffae33;
}

input[type="radio"].status-indicator.en-cours:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #ffae33;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.termine:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #069a77;
}

input[type="radio"].status-indicator.termine:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #069a77;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

.card-status-info {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  margin-bottom: 5px;
}
.status-indicator-a-faire {
  border: 1px solid #db4437;
  background-color: #db4437;
}
.status-indicator-en-cours {
  border: 1px solid #ffae33;
  background-color: #ffae33;
}
.status-indicator-termine {
  background: #069a77;
  border: 2px solid #069a77;
}
.text-status-indicator {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.75) !important;
  flex-direction: column;
  margin: 0 7px;
}
.tache-bakeliste-limit-hours {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  color: #085550;
}
.icon-tache-bakeliste-limit-hours {
  width: 14px;
  color: #069a77;
  margin-right: 15px;
}
.content-tache-bakeliste-view {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.lien-utile-bakeliste-tache-card {
  font-size: 14px;
  display: block;
  line-height: 1.3;
  margin-bottom: 5px;
}

input[type="radio"].status-indicator.a-faire:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;

  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #db4437;
}

input[type="radio"].status-indicator.a-faire:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #db4437;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.en-cours:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #ffae33;
}

input[type="radio"].status-indicator.en-cours:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #ffae33;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"].status-indicator.termine:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #069a77;
}

input[type="radio"].status-indicator.termine:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #069a77;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.page-item.active .page-link {
  z-index: 3; 
  color: #fff;
  background-color: #069a77;
  border-color: #069a77;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #069a77;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.date-reunion-zoom-card-dashboard-bakeliste{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #009688;
    margin-bottom: 35px;
}
.btn-reunion-zoom-card-dashboard-bakeliste{
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: .8rem 1rem .8rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid transparent;
    transition: .2s ease-in-out;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: rgba(0, 0, 0, 0.75);
}
.btn-reunion-zoom-card-dashboard-bakeliste:hover{
    text-decoration: none;
    border: 1px solid #069A77;
    color: #069A77;
}
.icon-btn-reunion-zoom-card-dashboard-bakeliste {
    width: 29px;
    position: absolute;
    left: 20px;
}

.li-save-reunion-dashboard-bakeliste{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(1, 31, 59, 0.75);
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
}
.icon-li-save-reunion-dashboard-bakeliste{
    width: 14px;
    color: #009688;
    margin-left: auto;
}
.component-tache-item {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 10px;
}
.titre-component-tache-item {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}
.content-component-tache-item {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}

.component-tache-item {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 10px;
}
.titre-component-tache-item {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}
.content-component-tache-item {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}

.component-livrable-bakeliste-view{
    padding: 20px auto 200px;
}

.component-livraison-bakeliste{
    
}
.livraison{
    margin-bottom: 40%;
}
.pt3{
    padding-top: 60px;
}
.component-livrable-bakeliste-item{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px 25px;
    margin-bottom: 15px;
}
.num-tache-livrable-bakeliste-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #069A77;
    margin-bottom: 10px;
}
.date-livrable-bakeliste-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.badge-livrable{
    font-size: 17px;
}
.separateur-livrable-bakeliste-item{
    border-left: 1px solid #C4C4C4;
    padding: 5px 15px;
}
.titre-tache-livrable-bakeliste-item{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 5px;
}

.content-tache-livrable-bakeliste-item{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.btn-livrable-bakeliste-item{
    border: 1px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #069A77;
    width: 100%;
    padding: .7rem 0;
    background: #ffffff;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-livrable-bakeliste-item:hover{
    background: #069A77;
    transition: .2s ease-in-out;
    color: #FFFFFF;
}
.icon-btn-livrable-bakeliste-item{
    margin-right: 10px;
    color: #EDEBE8;
    width: 15px;
    height: 15px;

}
.icon-btn-livrable-bakeliste-itemS{
    margin-right: 10px;
    color: #f96b57;
    width: 30px;
    height: 30px;
    cursor: pointer;

}
.btn-theme-red {
    background: #f96b57 !important;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #f96b57 !important;
  }
  .btn-theme-green {
    background: #f39831 !important;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #ffffff !important;
    padding: 0.8rem;
    transition: 0.2s ease-out;
    border: 1px solid #f39831 !important;
  }
  /* .btn-theme-red:hover {
    color: #f96b57 !important;
    background: #ffffff !important;
    transition: 0.2s ease-in;
  } */
  
.pagination-container{display:flex;list-style-type:none}.pagination-container .pagination-item{padding:0 12px;height:32px;text-align:center;margin:auto 4px;color:rgba(0,0,0,.87);display:flex;box-sizing:border-box;align-items:center;letter-spacing:.01071em;border-radius:16px;line-height:1.43;font-size:13px;min-width:32px}.pagination-container .pagination-item.dots:hover{background-color:rgba(0,0,0,0);cursor:default}.pagination-container .pagination-item:hover{background-color:rgba(0,0,0,.04);cursor:pointer}.pagination-container .pagination-item.selected{background-color:rgba(0,0,0,.08)}.pagination-container .pagination-item .arrow::before{position:relative;content:"";display:inline-block;width:.4em;height:.4em;border-right:.12em solid rgba(0,0,0,.87);border-top:.12em solid rgba(0,0,0,.87)}.pagination-container .pagination-item .arrow.left{-webkit-transform:rotate(-135deg) translate(-50%);transform:rotate(-135deg) translate(-50%)}.pagination-container .pagination-item .arrow.right{-webkit-transform:rotate(45deg);transform:rotate(45deg)}.pagination-container .pagination-item.disabled{pointer-events:none}.pagination-container .pagination-item.disabled .arrow::before{border-right:.12em solid rgba(0,0,0,.43);border-top:.12em solid rgba(0,0,0,.43)}.pagination-container .pagination-item.disabled:hover{background-color:rgba(0,0,0,0);cursor:default}
.link-groupe-whatsapp{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    background: #FFFFFF;
    border: 1px solid #49DD7B;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    display: flex;
    align-items: center ;
    transition: .2s ease-in-out;
    padding: 0 1rem 0 2rem;
    justify-content: center;
    height: 45px;
    position: relative;
}
.link-groupe-whatsapp:hover{
    text-decoration: none;
    transition: .2s ease-in-out;
    background-color: #49DD7B;
    color: #FFFFFF;
}
.icon-link-groupe-whatsapp{
    width: 45px;
    border-radius: 50%;
    position: absolute;
}
.link-groupe-whatsapp-mobile {
    border-radius: 100px;
    position: relative;
}
.header-table-groupe-view{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #069A77;
}

.component-groupe-bakeliste{
    
}

.component-table-groupe-bakeliste{
    
}

.component-dashboard-coach-view{
    
}

.component-header-dashboard-coach{
    
}

.component-nav-vertical-coach{
    
}
.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}


.component-statistics-coach{
    
}

.component-bakeliste-group-item-for-coach{
    
}
.btn-show-modal-bakeliste-details{
    padding: 2px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
    background: transparent;
}
.avatar-bakeliste{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.nom-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
.formation-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #011F3B;
}
.position-action-bakeliste-details{
    position: absolute;
    top: 60px;
    right: 20px;
}
.btn-action-bakeliste-details{
    background: #FFFFFF;
    border-radius: 35px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    text-align: justify;
    text-transform: uppercase;
    color: #010101;
    width: 100%;
    transition: .2s ease-in-out;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.btn-action-bakeliste-details:hover{
    color: #069A77;
    transition: .2s ease-in-out;
    text-decoration: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);

}
.icon-btn-action-bakeliste-details{
    width: 14px;
    margin-right: 10px;
    color: #069A77;
    height: auto;
}
.delete-btn-action-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    text-align: justify;
    text-transform: uppercase;
    color: #F96B57;
    transition: .2s ease-in-out;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background: #FFFFFF;
    border-radius: 35px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    width: 100%;
}
.delete-btn-action-bakeliste-details:hover{
    background-color: #F96B57;
    color: #ffffff;
    transition: .2s ease-in-out;
}
.icon-delete-btn-action-bakeliste-details{
    width: 14px;
    margin-right: 10px;
    height: auto;
}
.content-informations-bakeliste-details{
    padding: 15px 25px 0;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
}
.titre-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    margin-bottom: 25px;
}
.h-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
}
.text-content-informations-bakeliste-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
}
.text-content-informations-bakeliste-details.mail-bakeliste{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.component-program-reunion-coach{
    
}
.component-table-my-bakelistes {
}

.character-bullets-3 {
  list-style: square;
  cursor: default;
}

.character-bullets-2 li::before {
  content: "👎";
  margin: 0px 20px 0 0px;
}

.character-bullets-3 li::before {
  /* content: "📚"; */
  /* margin: 0px 20px 0 0px; */
  /* color: pink; */
  font-size: 30px;
}

.cursor {
  cursor: pointer;
}


.component-livraison-coach-assistant{
    
}.component-livraison-coach {
}

.parentDisable {
  position: fixed;

  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}
.input-group1{
  position: relative;
  display: flex;
  align-items: stretch;
  width: 72%;
  background-color: white;
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
}
.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 1000;
  position: fixed;
}


.component-graphe-livraisons{
    
}

.component-graphe-commentaires{
    
}

.component-graphe-contenu{
    
}


.component-tache-coach{
    
}

.component-send-task-to{
    
}

.component-table-gestion-tache-for-coach{
    
}
.content-nav-fil-actualite-livraison{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 15px 50px;
    position: fixed;
    width: 100%;
}
.nav-fil-actualite-livraison{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
    padding: 10px 0;
}
.nav-fil-actualite-livraison:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.nav-pills .nav-fil-actualite-livraison.active, .nav-pills .show>.nav-fil-actualite-livraison {
    color: #069A77;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.date-livraison-fil-d-actualite{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #DAF8F4;
    border-radius: 10px;
    text-align: center;
    color: #055F49;
    padding: 6px 12px;
    display: inline-flex;
    margin-bottom: 10px;
}
.content-fil-actualite{
/*     overflow: auto;
 */    height: 100vh;
}

.component-livraison-coach {
}

.parentDisable {
  position: fixed;

  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}
.input-group1{
  position: relative;
  display: flex;
  align-items: stretch;
  width: 72%;
  background-color: white;
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
}
.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 1000;
  position: fixed;
}


.component-groupe-coach-view{
    
}
.groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px ;
    line-height: 29px;
    color: #069A77;
}
.btn-add-groupe{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-archive{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-modifier{
    background: transparent;
    font-size: 14px;
    line-height: 17px;
    color: #469789;; 
    /* border-radius: 1rem; */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-top: 10px;


}

  
.lien-whatsapp {
    font-size: 14px;
    display: block;
    line-height: 1.3;
    margin-bottom: 5px;
    color: blue;
    text-decoration: underline;

    
  }
.btn-archiver{
     background: transparent;
    /* font-size: 12px;
    line-height: 17px; */
    color: #e6ab0a;
    margin-top: 13px;
}
.icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
}
.add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
}
.component-offlinecoach {
  font-family: "Rubik", sans-serif;
  background: linear-gradient(180deg, rgb(255, 166, 166), #fff);
  background-repeat: no-repeat;
}

.containerr {
  margin-top: 100px !important;
}

h3.h33 {
  color: #9e9e9e;
  font-size: calc(20px + 6 * ((100vw - 320px) / 680));
}

button.buttonn:focus {
  box-shadow: none !important;
  outline-width: 0;
}

.cardd {
  border-radius: 0;
  /* width: calc(475px + 10 * ((100vw - 320px) / 680)); */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);
}

.card-headerr {
  /* background-color: #f44236 !important; */
  color: #f44236;
}

img.offline {
  /* width: 180px !important; */
  width: 50% !important;
}

.btn-successs {
  border: 1px solid #009688;
  padding: 8px 20px 8px 20px !important;
  border-radius: 20px !important;
  color: #fff !important;
}

.btn-successs:corner-present {
  background: #009688;
  color: #fff !important;
  border-color: #009688 !important;
}

.btn-successs:focus {
  background: #009688 !important;
  color: #fff !important;
}

.btn-successs {
  background: #009688 !important;
}

.inner li {
  list-style-type: disc !important;
}

@media (max-width: 654px) {
  .cardd {
    width: unset;
  }
}


.component-syllabus-coach-view{
    
}
.input-syllabus-search > .rs-input {
  border: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}
.add-group{
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.tope{
  top: 20px;
}
.icon-add-groupe{
  font-size: 20px;
  color: #FFFFFF;
}
/* radio button */
.btn-archive {
  /* background-color: white;
  font-size: 14px;
  font-weight: bold;
  color: #4b9b77; */

  font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    border-radius: 0.5rem;
}
.radiobtn {
  position: relative;
  display: block;
  padding: 1.2em;
}
.radiobtn label {
  display: block;
  background: #05a192cc;
  color: #fff;
  border-radius: 5px;
  padding: 8px 18px;
  border: 2px solid #009688cc;
  margin-bottom: 5px;
  cursor: pointer;
}
.radiobtn label:after,
.radiobtn label:before {
  content: "";
  position: absolute;
  right: 11px;
  top: 11px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #009688cc;
}
.tn-theme-light{
  padding: 0.5rem 1rem;
  background: #ffffff;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.065em;
  color: #069a77;
  border: 1px solid transparent;
  
}
.radiobtn label:before {
  background: transparent;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}
.radiobtn input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
          appearance: none;
}
.radiobtn input[type="radio"]:checked + label {
  background: #05a192cc;
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  border-color: #009688cc;
}
.radiobtn input[type="radio"]:checked + label:after {
  background: #009688cc;
}
.radiobtn input[type="radio"]:checked + label:before {
  width: 20px;
  height: 20px;
}
@-webkit-keyframes blink {
  0% {
    background-color: #05a192cc;
  }
  10% {
    background-color: #05a192cc;
  }
  11% {
    background-color: #009688cc;
  }
  29% {
    background-color: #009688cc;
  }
  30% {
    background-color: #009688cc;
  }
  50% {
    background-color: #05a192cc;
  }
  45% {
    background-color: #009688cc;
  }
  50% {
    background-color: #05a192cc;
  }
  100% {
    background-color: #009688cc;
  }
}
@keyframes blink {
  0% {
    background-color: #05a192cc;
  }
  10% {
    background-color: #05a192cc;
  }
  11% {
    background-color: #009688cc;
  }
  29% {
    background-color: #009688cc;
  }
  30% {
    background-color: #009688cc;
  }
  50% {
    background-color: #05a192cc;
  }
  45% {
    background-color: #009688cc;
  }
  50% {
    background-color: #05a192cc;
  }
  100% {
    background-color: #009688cc;
  }
}

.col-lg-6.col-md-4.col-sm-6.mb-4.px-lg-3.px-md-2 {
  display: inline-block;
}

.recherche{
  border-radius: 2rem;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc;
}



.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: inline;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
    cursor:pointer
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.btn-share-to{
    padding: 0;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .2s ease-in-out;
}
.btn-share-to:hover{
    background: none;
    color: #ffffff;
    transition: .2s ease-in-out;
}

.component-syllabus-bakeliste{
    
}
.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: inline;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.component-syllabus-item-disabled{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    opacity: .5;
    cursor: no-drop;
    position: relative;
}

.component-notification-page{
    
}

.component-account-bakeliste-view{
    
}

.component-account-bakeliste{
    
}
.navigation-previous-account {
    position: absolute;
    left: -20px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
    top: -1px;
}
.navigation-previous-account:hover {
    color: #069A77;
}
.titre-navigation-previous-account{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #011F3B;
}

.label-account{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #5A5A5A;
    margin-bottom: 3px;
}
.input-account{
    background: #FAFAFA;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    border: 0;
    line-height: 17px;
    color: #000000;
    transition: .2s ease-in-out;
}
.input-account:focus{
    background-color: #069a7721;
    transition: .2s ease-in-out;
}
.avatar-account{
    width: 100%;
    border-radius: 12px;
}
.upload-new-avatar-account>.rs-uploader-trigger>.rs-uploader-trigger-btn{
    background: #FAFAFA;
    border-radius: 10px;
    border: transparent;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #5A5A5A;
}
.delete-icon-avatar-account{
    position: absolute;
    right: -30px;
    top: 5px;
    color: #F42D2D;
    padding: 0;
    background: transparent;
}
.icon-avatar-account{
    width: 16px;
}


.component-account-coach-view{
    
}

.component-account-coach{
    
}

.component-syllabus-details-bakeliste-view{
    
}

.component-syllabus-details-bakeliste{
    
}
.titre-syllabus-details-card{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.add-tache-syllabus{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.etiquette-syllabus-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 3px 15px;
    border-radius: 35px;
    display: inline-block;
}


.etiquette-syllabus-details.debutant{
    background: #EC0303;
}
.etiquette-syllabus-details.intermediaire{
    background: #f3bc07;
}
.etiquette-syllabus-details.avance{
    background: #069A77;
}
.icon-add{
    font-size: 20px;
    color: #069A77;
}
.btn-add-with-icon-plus-circle{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    background: transparent;

    align-items: center;
    transition: .2s ease-in-out;
    background-color: #ffffff;
    border-radius: 10px;
    height: 36px;
    width: 100%;

    font-weight: 600;   
     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);


}
.btn-add-with-icon-plus-circle:hover{
    transition: .2s ease-in-out;
    color: #069A77;
}
.content-syllabus-details-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 163.9%;
    color: #000000;
}
.navigation-previous{
    position: absolute;
    left: 15px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
}
.navigation-previous:hover{
    color: #069A77 !important;
    transition: .2s ease-in-out;
}

.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}
.groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}
.lire-suite-btn{
    background: #009688;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding: .5rem 2rem;
    border: 1px solid #009688;
    transition: .2s ease-in-out;
}
.lire-suite-btn:hover{
    transition: .2s ease-in-out;
    border: 1px solid transparent;
    background-color: #FFFFFF;
    color: #009688;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.ri-content{
    padding: 25px 35px 100px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 15px;
}
.ri-logo{
    width: 65px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
}


.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}


.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}

.groupe-titre-on-coach-dashboard {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #069a77;
}
.ajouter {
  color: #069a77;
  height: 20px;
  width: 23px;
}
.icon-add-sgroupe {
  font-size: 20px;
  color: #ffffff;
}
.btn-add-sgroupe {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  border-radius: 1rem;
  height: 36px;
  background: #469789;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.add-sgroup {
  color: white;
  font-size: 14px;
  font-weight: bold;
}
.voir {
  color: #ffc107;
  height: 23px;
  width: 23px;
}
.edit {
  color: #080701;
  height: 23px;
  width: 23px;
}


.component-sous-groupe-coach-assistant{
    
}.groupe-titre-on-coach-dashboard {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #069a77;
  }
  .ajouter {
    color: #069a77;
    height: 20px;
    width: 23px;
  }
  .icon-add-sgroupe {
    font-size: 20px;
    color: #ffffff;
  }
  .btn-add-sgroupe {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    transition: 0.2s ease-in-out;
    border-radius: 1rem;
    height: 36px;
    background: #469789;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .add-sgroup {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  .voir {
    color: #ffc107;
    height: 23px;
    width: 23px;
  }
  .edit {
    color: #080701;
    height: 23px;
    width: 23px;
  }
  

.component-nav-vertical-coach-assistant{
    
}

.component-bakeliste-groupe-item-for-coach-assistant{
    
}

.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}

.groupe-archived-titre-on-coach-dashboard {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #069a77;
}

.sous_groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #069A77;
}
.btn-add-groupe{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-archive{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-modifier{
    background: transparent;
    font-size: 14px;
    line-height: 17px;
    color: #469789;; 
    /* border-radius: 1rem; */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-top: 10px;


}
.btn-archiver{
     background: transparent;
    /* font-size: 12px;
    line-height: 17px; */
    color: #e6ab0a;
    margin-top: 13px;
}
.icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
}
.add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
}


.component-liste-taches-coach-view {
}

.error-text {
  color: red;
  text-align: center;
}
.inputR {
  margin-top: -7px;
  background-color: #f2faf9;
  width: 57%;
  margin-left: -6px;
  height: 96px;
}
.rechercheTache {
  border-radius: 2rem;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc;
}
/* .css-yk16xz-control {
  border-radius: 2rem !important;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc !important;
} */
/* .fixed {
  position: fixed;
  top: 88px;
  z-index: 1030;
} */
.fixer {
  position: fixed;
  top: 134px;
  z-index: 1030;
  width: 28.5%;
}
.fixe {
  position: fixed !important; 
  /* top: 200px; */
  top: 15% !important;
  z-index: 1031 !important;
  width: 20% !important;
}

.search{
  z-index: 1030;
  margin-top: -4%;
  position: fixed;
  width: auto;
}
.size{
  width: 100%;
  margin-left: -16px;
}

.syl {
  margin-top: 13%;
}
.fis {
  position: fixed;
  /* top: 200px; */
  top: 195px;
  z-index: 1030;
  width: 34%;
  margin-top: -61px;
  height: 17%;
}
.fixeS {
  position: fixed;
  top: 44px;
  z-index: 1030;
}
.color {
  background-color: #f2faf9;
}

/* .css-2b097c-container {
  width: 30%;
  z-index: 1030;

  box-sizing: border-box;
  position: fixed;
  margin-left: 32%;
  margin-top: 57px;
} */
.searchSyllabus {
  border-radius: 2rem;
  width: -webkit-fill-available;
  height: 33px;
  border-color: #009688cc;
}
.form-control-costom {
  border: none;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.tache {
  top: 39px;
}
.form-control-costom {
  border: none;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
.iconInput {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding-right: 10px;
  background-color: white;
}
.input {
  margin-left: 33rem;
}
/* 
.allTache{
  top:-5px;
} */

.numero-tache-syllabus-item-tache {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: #069a77;
}
.duree-tache-syllabus-item-tache {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.titre-syllabus-item-tache {
  font-family: "Inter", sans-serif;
}
.allTache {
  top: 62px;
}
.titre-syllabus-item-tache {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: #000000;
}
.contenu-syllabus-item-tache {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.dropdown-syllabus-item-tache {
  position: absolute;
  right: 10px;
  top: 25%;
}
.icon-dropdown-syllabus-item-tache {
  width: 30px !important;
  height: 30px !important;
}
.dropdown-syllabus-item-tache > .rs-dropdown-toggle > .rs-btn-default {
  background-color: transparent;
  width: 30px;
  height: 30px;
}

img.thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}
img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}


.component-liste-taches-coach-assistant-view{
    
}

.component-syllabus-coach-item-tache-assistant{
    
}.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}

.component-liste-bakelistes-coach-view{
    
}

.component-liste-bakelistes-coach{
    
}
.component-table-bakeliste {
}

.label-color-green {
  color: #069a77;
  font-weight: 500;
}

.formation-courte {
  background-color: #069a77;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px;
} 

.formation-longue {
  background-color: orange;
  color: white;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px;
}

.training-time {
  background-color: #b5915c;
  color: white;
  padding: 5px;
}


.component-liste-sous-groupes-coach-view{
    
}

.component-liste-sous-groupes-coach{
    
}

.component-syllabus-archives-coach-view{
    
}

.component-syllabus-archive-coach-item{
    
}

.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center; 
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: inline;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
    cursor:pointer
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.component-syllabus-details-coach-view2{
    
}

.component-syllabus-details-coach2{
    
}

.component-syllabus-coach-inner-details{
    
}
.mto-3{
    margin-top: 3rem;
}
.titre-syllabus-details-card{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.add-tache-syllabus{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.etiquette-syllabus-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 3px 15px;
    border-radius: 35px;
    display: inline-block;
}


.etiquette-syllabus-details.debutant{
    background: #EC0303;
}
.etiquette-syllabus-details.intermediaire{
    background: #f3bc07;
}
.etiquette-syllabus-details.avance{
    background: #069A77;
}
.icon-add{
    font-size: 20px;
    color: #FFFFFF;
}
.icon-add-tache{
    font-size: 20px;
    color: #FFFFFF;
}
.btn-add-with-icon-plus-circle{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #069A77;
    background: transparent;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    background-color: white;
border-radius: 1rem;
height: 36px;
width: 100%;
}
.aaaaa{
    color: white;
    font-size: 17px;
    font-weight: 500;
}

.btn-add-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
    border-radius: 0.6rem;

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}
.btn-add-with-icon-plus-circle:hover{
    transition: .2s ease-in-out;
    color: #069A77;
}
.content-syllabus-details-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 163.9%;
    color: #000000;
}
.navigation-previous{
    position: absolute;
    left: 15px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
}
.navigation-previous:hover{
    color: #069A77 !important;
    transition: .2s ease-in-out;
}

.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}

.component-fiche-bakeliste{
    
}

.taille{
  height: 100px;  
}

.component-fiche-bakeliste-coach-assistant{
    
}

.taille{
  height: 100px;  
}

.component-information-sous-groupe{
    
}

.component-information-sous-groupe-coach{
    
}

.component-detail-sous-groupe{
    
}

.component-liste-tache-bakeliste-view{
    
}

.component-liste-tache-bakeliste{

}

.input-group1{
    position: relative;
    display: flex;
    align-items: stretch;
    width: 72%;
    background-color: white;
    border: 0.5px solid #069a77;
    box-sizing: border-box;
    border-radius: 10px;
}
.search_icon{
    color:  #069a77;
    position: absolute;
    /* left: 90px; */
    top: 8px;
}
.size1{
    width: 100%;
    /* margin-top: 41px; */
    position:absolute;
    right:1rem;
    top:50%;
    margin-left: -18px;
}
.SearchB {
    border: 0.5px solid #069a77;
    box-sizing: border-box;
    border-radius: 10px;
    padding-right: 10px;
    background-color: white;

  }
  .pt6{
      padding-top: 70px;
  }

  .pagination-container .pagination-item.selected{
    background-color: #069a77 !important;
    color: white
  }

  .pagination-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  .pagination-container .pagination-item{
        padding: 0 12px;
        height: 42px!important;
        text-align: center;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.01071em;
        border-radius: 50% !important;
        line-height: 1.43;
        font-size: 16px;
        min-width: 42px !important;
  }

  /* BARRE DE RECHERCHE */
  .form-control-costom{
    padding-left: 1.7rem!important;
    width: 100%!important;
    /* position: relative; */
  }
  .container-search{
    width: 40%!important;
    position: relative;
    padding-right: 3.1rem;
  }

.component-demande-absence-view{
    
}

.component-demande-absence{
    
}

.label-danger{
    background-color: #eb4234;
    color:white;
    border-radius: 7px;
    font-weight: bold;
    text-align: center;
    padding: 10%;

    
}
.editColor{
    color: #469789;
}

.voirColor{
    color: #f39831;
}
.label-warning{
    background-color:#f39831;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    padding: 10%;


}

.label-success{
    background-color: #469789;
    color:white;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    padding: 10%;


}
.changed-status-demande {
    background: #f39831 !important;
    color: #000;
  }
  

.component-view-liste-questions{
    
}
.component-content-liste-questions {
}

.orange-icon {
  color: #ff9800;
  cursor: pointer;
}

.red-icon {
  color: #f44336;
  cursor: pointer;
}

.green-icon {
  color: #009688;
}


.component-view-liste-questions-coach-assistant{
    
}

.component-content-liste-questions-coach-assistant{
    
}
.component-add-question {
}
.component-component-question-quiz {
}

.questionnaire {
  background: #ffffff;
  border: 0.25px solid #666666;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.add-question {
  color: white;
  background: #009688;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  padding: 1rem;
}
.btn-add-exam {
  color: white;
  background: #ff9800;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #009688;
  background: #fff;
  white-space: nowrap;
  padding: 2rem;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}

.form-control1 {
  height: 90%;
}

.select {
  width: 100%;
  padding-left: 1rem;
}
.select-one {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.select-two {
  width: 8.5rem;
  padding-left: -1rem;
  padding-right: 2rem;
}

.affichage-correction {
  width: 200px;
  height: 54px;
  background: rgba(0, 150, 136, 0.5);
  border-radius: 5px;
  padding-left: 1rem;
}

.toggle-btn {
  width: 56px;
  height: 22px;
  background: #9dadd3;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s 0.1s ease-in-out;
}

.toggle-btn > .inner-circle {
  width: 13px;
  height: 13px;
  background: #009688;
  border-radius: 50%;
  transition: all 0.3s 0.1s ease-in-out;
}

.toggle-btn.active {
  background: blue !important;
}

.toggle-btn.active > .inner-circle {
  margin-left: 50px;
}

.component-ajouter-quiz-coach {
}
.title_quizz {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ff9800;
}
.bg-add-quiz {
  background-color: white;
  padding: 3rem;
}
.input-add-quiz {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: white;
}
.btn-add-quiz {
  background: #009688;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 1rem;
}
.btn-add-quiz:hover {
  color: white;
}
.modal-quiz {
  text-align: center;
  color: #009688;
  padding: 1rem;
}
.bg-icon-modal {
  background: #edecea;
  border-radius: 50%;
}
.icon-close {
  color: red;
}
.texte_modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #069a77;
}
.btn-theme-orange {
  background: #ff9800 !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid #ff9800 !important;
}
.btn-theme-danger {
  background: red !important;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.08em;
  color: #ffffff !important;
  padding: 0.8rem;
  transition: 0.2s ease-out;
  border: 1px solid red !important;
}


.component-content-questions{
    
}

.component-view-questions{
    
}

.component-add-question-coach-assistant{
    
}

.component-content-question-coach-assistant{
    
}

.component-view-questions-coach-assistant{
    
}

.component-edit-question{
    
}

.component-view-edit-question{
    
}

.component-faire-examen-view{
    
}
.component-faire-examen {
}

.component-content-examen-theorique {
}

.nav-theorique {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #009688;
  border: none;
}
.nav-theorique:active {
  border: none;
}

.card-exam {
  height: 100%;
  border: none;
  border-radius: 2px;
  background: #fff;
}

.partie-theorique {
  background: #009688;
  padding-top: 2rem;
  padding-left: 2rem;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.part-article {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}
.part-article h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-article-par p {
  width: 279px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.part-article1 {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}

.part-article1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-two-title {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.exam-question-one {
  width: 98%;
  height: 66px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.exam-question-one p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.exam-btn-next {
  padding-left: 60%;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.exam-btn {
  color: #ffffff;
  width: 314.17px;
  height: 50px;
  left: 775px;
  top: 813px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.exam-btn:hover {
  color: white;
  text-decoration: none;
}

/* button radio */
.radio-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-row:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  background-color: #009688;
  box-shadow: 2px 2px 10px 2px rgb(255, 152, 0);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-row:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  color: #009688;
  background-color: #ffff;
  box-shadow: 2px 2px 20px 2px rgb(255, 152, 0);
  border-radius: 30px;
  width: 100%;
}

.radio-btn-wrapper {
  margin: 0px 4px;
}

.radio-btn {
  background-color: #ff9800;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.055em;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  color: #000000;
  padding: 1rem;
}

.radio-btn-selected {
  background-color: #ffffff;
  border: 1px solid #55bc7e;
  color: #55bc7e;
  font-size: 14px;
  line-height: 26px;
  outline: none;
}
.libelle-reponse {
  color: white;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 62px;
  letter-spacing: 0.055em;
}

.component-resultat-examen-theorique {
}

.component-content-resultat-exam-theorique {
}

.card-resultat {
  background: #fff;
}

.resultat-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.055em;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.score-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.score-number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.055em;
  color: #009688;
}
.card-resultat-two {
  background: #ffffff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.percentage {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}

.text-prochain {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.result-final {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.055em;

  /* #009688 */
  text-align: center;
  color: #009688;
}
.txt-not-final {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}
.txt-note {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.055em;

  color: rgba(0, 0, 0, 0.8);
}


.component-examen-coach-view{
    
}

.component-examen-coach{
    
}
.nav-pills1{
    width: 100%;
    padding-top: 3rem;
    /* padding-left: 3rem; */
    padding-bottom: 1rem;
}
.ddr{
    margin: 1rem;
}
.nav-item1{
    font-size: 15px;
    font-weight: bold;
}
.nav-link1{
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    background-color: #009688;
    color: white; 
    border-radius: 0px 20px 0px; 
    border: #dbf1ef 1px solid;
    font-family: 'Montserrat';
    border: #dbf1ef 1px solid;

}
.nav-link1:hover{
    color: white;
}
.nav-link1.active{
    background-color:inherit;
    color: #009688;
    border-radius: inherit;
    border-bottom: 1px solid;
    border-top: inherit;
    border-left: inherit;
    border-right: inherit;

    /* border-radius: 10px; */
}

.component-examen-item{
    
}

.component-examen-item{
    
}

.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}


.component-examen-item-question{
    
}

.component-examen-item-question{
    
}
.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}


.component-examen-item{
    
}

.component-examen-item{
    
}

.syllabus-item1 {
    opacity: .5;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}

.component-examen-item {
}

.component-examen-item {
}

.syllabus-item1 {
  opacity: 0.5;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 15px;
}

.container-syllabus-item-back {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.74);
  border-radius: 15px;
  padding: 2rem 2rem 3rem;
}

.container-syllabus-item {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none !important;
  display: flex;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.container-syllabus-item:hover .syllabus-item {
  /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
  opacity: 1;
}

.content-syllabus-item {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0 0 15px 15px;
  position: relative;
}
.image-syllabus-item {
  border-radius: 15px 15px 0 0;
  width: 100%;
}

.titre-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #0f0f0f;
  margin-bottom: 15px;
}
.icon-content-syllabus-item {
  color: #009688;
  width: 12px;
  margin-right: 10px;
}
.info-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(13, 12, 12, 0.8);
  display: flex;
  align-items: center;
}
.titre-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 10px;
}
.content-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.etiquette-syllabus-item {
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant {
  background: #ec0303;
}
.etiquette-syllabus-item.confirme {
  background: #069a77;
}
.position-action-container-syllabus-item-back {
  position: absolute;
  left: 15px;
  width: 100%;
  bottom: 15px;
}
.link-details-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffffc9;
  transition: 0.1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover {
  color: #ffffff;
  transition: 0.1s ease-in-out;
  text-decoration: none;
}
.icon-details-container-syllabus-item-back {
  width: 15px;
  margin-right: 8px;
}
.height-container-syllabus-item-back {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.bg-debutant {
  background: #dd4b39 !important;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-intermediaire {
  background: #f3bc07;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-avance {
  background: #069a77;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}

.transp {
  background: transparent;
}


.component-examen-coach-assistant-view{
    
}

.component-examen-coach-assistant{
    
}
.component-examen-coach{
    
}
.nav-pills1{
    width: 100%;
    padding-top: 3rem;
    /* padding-left: 3rem; */
    padding-bottom: 1rem;
}
.ddr{
    margin: 1rem;

}
.nav-item1{
    font-size: 15px;
    font-weight: bold;
}
.nav-link1{
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    background-color: #009688;
    color: white; 
    border-radius: 0px 20px 0px; 
    border: #dbf1ef 1px solid;
    font-family: 'Montserrat';
    border: #dbf1ef 1px solid;

}
.nav-link1:hover{
    color: white;
}
.nav-link1.active{
    background-color:inherit;
    color: #009688;
    border-radius: inherit;
    border-bottom: 1px solid;
    border-top: inherit;
    border-left: inherit;
    border-right: inherit;

    /* border-radius: 10px; */
}

.component-examen-item-coach-assistant{
    
}
.component-examen-item{
    
}

.component-examen-item{
    
}

.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}


.component-examen-item-theo-coach-assistant{
    
}
.component-examen-item-question{
    
}

.component-examen-item-question{
    
}
.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}


.component-examen-item-disabled-coach-assistant{
    
}
.component-examen-item{
    
}

.component-examen-item{
    
}

.syllabus-item1 {
    opacity: .5;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}


.component-examen-theo-item-disabled-coach-assistant{
    
}.component-examen-item {
}

.component-examen-item {
}

.syllabus-item1 {
  opacity: 0.5;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 15px;
}

.container-syllabus-item-back {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.74);
  border-radius: 15px;
  padding: 2rem 2rem 3rem;
}

.container-syllabus-item {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-decoration: none !important;
  display: flex;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.container-syllabus-item:hover .syllabus-item {
  /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
  opacity: 1;
}

.content-syllabus-item {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0 0 15px 15px;
  position: relative;
}
.image-syllabus-item {
  border-radius: 15px 15px 0 0;
  width: 100%;
}

.titre-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #0f0f0f;
  margin-bottom: 15px;
}
.icon-content-syllabus-item {
  color: #009688;
  width: 12px;
  margin-right: 10px;
}
.info-content-syllabus-item {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(13, 12, 12, 0.8);
  display: flex;
  align-items: center;
}
.titre-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 10px;
}
.content-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.etiquette-syllabus-item {
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant {
  background: #ec0303;
}
.etiquette-syllabus-item.confirme {
  background: #069a77;
}
.position-action-container-syllabus-item-back {
  position: absolute;
  left: 15px;
  width: 100%;
  bottom: 15px;
}
.link-details-container-syllabus-item-back {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ffffffc9;
  transition: 0.1s ease-in-out;
}
.link-details-container-syllabus-item-back:hover {
  color: #ffffff;
  transition: 0.1s ease-in-out;
  text-decoration: none;
}
.icon-details-container-syllabus-item-back {
  width: 15px;
  margin-right: 8px;
}
.height-container-syllabus-item-back {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.bg-debutant {
  background: #dd4b39 !important;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-intermediaire {
  background: #f3bc07;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}
.bg-avance {
  background: #069a77;
  position: absolute;
  top: 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 4px 20px 4px 10px;
  border-radius: 0 30px 30px 0;
}

.transp {
  background: transparent;
}


.component-details-examen-coach-view{
    
}

.component-detail-examen-pratique{
    
}

.component-detail-pratique{
    
}

.component-add-question-examen{
    
}
.icon-add1{
    font-size: 20px;
    color: white;
}


.component-ajout-question-coach{
    
}

.component-ajout-question-coach-assistant{
    
}
.component-ajout-examen-pratique {
}

.component-content-ajout-exam-pratique {
}


.title-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #069a77;
}

.bg-ajout-question {
  background-color: white;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 15px;
}

.input-add-quiz {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.055em;
}

.btn-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding-bottom: 1rem;
  width: 249px;
  height: 50px;
  background: #ff9800;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.btn-ajout-question:hover {
  color: white;
}


.component-ajout-examen-pratique-coach-assistant{
    
}.component-ajout-examen-pratique {
}
.component-content-ajout-exam-pratique {
}
.title-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #069a77;
}

.bg-ajout-question {
  background-color: white;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 15px;
}

.input-add-quiz {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.055em;
}

.btn-ajout-question {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding-bottom: 1rem;
  width: 249px;
  height: 50px;
  background: #ff9800;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.btn-ajout-question:hover {
  color: white;
}


.component-ajout-examen-theorique{
    
}

.component-ajout-exam-theorique-coach-assistant{
    
}

.component-ajouter-resume-view{
    
}

.component-ajouter-resume{
    
}
.ht {
    height: 120%;
}

.editorClassName.rdw-editor-main {
    background-color: white;
    /* width: 114px; */
    height: 200px;
}

.component-detail-examen-pratique-view{
    
}

.component-details-examen-coach{
    
}

.component-details-examen{
    
}

.card-dashboard{
    padding: 15px 20px;
    background: #FFFFFF;
    border-radius: 5px;
    
}
.titre-syllabus-details-card{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
    display: flex;
    align-items: center;
}
.etiquette-syllabus-details{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 3px 15px;
    border-radius: 35px;
    display: inline-block;
}
.etiquette-syllabus-details.debutant{
    background: #EC0303;
}
.etiquette-syllabus-details.intermediaire{
    background: #f3bc07;
}
.etiquette-syllabus-details.avance{
    background: #069A77;
}
.icon-add{
    font-size: 20px;
    color: #069A77;
}
.btn-add-with-icon-plus-circle{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: transparent;
    display: flex;
    align-items: center;
    transition: .2s ease-in-out;
}
.btn-add-with-icon-plus-circle:hover{
    transition: .2s ease-in-out;
    color: #069A77;
}
.content-syllabus-details-card{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 163.9%;
    color: #000000;
}
.navigation-previous{
    position: absolute;
    left: 15px;
    color: #000000;
    transition: .2s ease-in-out;
    display: block;
    z-index: 1;
}
.navigation-previous:hover{
    color: #069A77 !important;
    transition: .2s ease-in-out;
}
.icon-navigation-previous{
    
}


.component-examen-item-question{
    
}

.component-examen-item-question{
    
}
.numero-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #069A77;
}
.duree-tache-syllabus-item-tache{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.titre-syllabus-item-tache{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: #000000;
}
.contenu-syllabus-item-tache{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
    color: #000000;
}
.dropdown-syllabus-item-tache{
    position: absolute;
    right: 10px;
    top: 25%;
}
.icon-dropdown-syllabus-item-tache{
    width: 30px !important;
    height: 30px !important;
}
.dropdown-syllabus-item-tache>.rs-dropdown-toggle>.rs-btn-default{
    background-color: transparent;
    width: 30px;
    height: 30px;
}



.component-liste-des-bakeliste-for-exam{
    
}
.liste-bakeliste-exam {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.055em;
    color: #000000;

}
.imglistebakeliste {
    width: 42px;
    height: 42px;
}
.liste-costom {
    list-style-type: none;
    text-decoration: none;
    border: none;
    white-space: nowrap;
}
.nom-bakeliste {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.055em;
    color: rgba(0, 0, 0, 0.8);
}


.component-note-examen{
    
}
.type-input-search{
    border: 1px solid #009688;
    box-sizing: border-box;
    border-radius: 10px;
}
.type-input-search:focus{
    border: 1px solid #009688;
}
.has-search{
    padding-right: 30%;
    margin-right: 15px;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    padding-left: 45%;
}
.format-txt-h4-examen{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.055em;
    color: #009688;
}
.btn-export-to{
    background: #FF9800;
    border-radius: 10px;
    height: 38px;
    color: #fff;
}

.component-table-notes-all-bakeliste{
    
}
.t-b-color{   
    justify-content: center;
    padding: 5px;
    background: rgba(0, 150, 136, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
    display: flex;
}
.b-color{
    justify-content: center;
    display: flex;
    padding: 5px;
    background: rgba(255, 152, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
}
.p-m-color{
    justify-content: center; 
    display: flex;
    padding: 5px;
    background: rgba(244, 67, 54, 0.5);
    border-radius: 5px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.055em;
}
.t-btn-mention{
    width: 88px;
}
.la-taille-div {
    height: 40%;
    font-size: 10px;
  }
  .title-table-notes {
    height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
      
  }
  .table-titles {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.055em;
    color: #009688;
  }


.component-note-examen-view{
    
}

.component-content-examen-bakeliste-view{
    
}

.component-envoyer-mon-examen{
    
}
.component-content-examen-bakeliste {
}

.component-content-exam-theorique-two {
}

.oo{
  margin-left: 9%;
}

.nav-theorique {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #009688;
  border: none;
}
.nav-theorique:active {
  border: none;
}

.card-exam {
  height: 100%;
  border: none;
  border-radius: 2px;
  background: #fff;
}

.partie-theorique {
  background: #009688;
  padding-top: 2rem;
  padding-left: 2rem;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.part-article {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}
.part-article h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-article-par p {
  width: 279px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.part-article1 {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}

.part-article1 h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.progress {
  width: 185px;
  height: 15px;
  left: 511px;
  top: 268px;
  background: #01564e;
  border-radius: 20px;
}
.progress-bar {
  /* width: 75%; */
  width: 100%;
  background: #ff9800;
  border-radius: 20px;
}

.part-two-title p {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.exam-question-one {
  width: 98%;
  height: 66px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.exam-question-one p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.btn-precedant {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #ff9800;
  border-radius: 10px;
}

.exam-btn-precedant {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}

.exam-btn-precedant:hover {
  color: white;
  text-decoration: none;
}

.btn-voir-resultat {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #009688;
  border-radius: 10px;
}

.exam-btn-voir-resultat {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}
.exam-btn-voir-resultat:hover {
  color: white;
  text-decoration: none;
}

.card-exam-pratique {
  border: none;
  background: #ffffff;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
}

.exam-pratique-entete {
  width: 100%;
  height: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.exam-pratique-entete p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.modul-exam p {
  font-weight: 600;
}

.sujet-pratique p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.domaine p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.domaine span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.niveau p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.niveau span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.duree p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.duree span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.sujet p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.sujet span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.sujet-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.travail-a-faire p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.travail-a-faire-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-one span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-one p i {
  color: #666666;
}
.travail-two span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-two p i {
  color: #666666;
}

.nb p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.nb span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.btn-precedant-pratique {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #ff9800;
  border-radius: 10px;
}

.exam-btn-precedant-pratique {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}

.exam-btn-precedant-pratique:hover {
  color: white;
  text-decoration: none;
}

.btn-voir-resultat-pratique {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #009688;
  border-radius: 10px;
}

.exam-btn-voir-resultat-pratique {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}
.exam-btn-voir-resultat-pratique:hover {
  color: white;
  text-decoration: none;
}
.start-exam {
  background: white;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}


.component-liste-coach-view{
    
}
#assignBody {
  font-family: "Din";
}

#btn_assign {
  background-color: #f9f9f9;
  -webkit-text-fill-color: #009688;
  border-color: #d1d1d1;
  font-family: "Din";
}
.add-coach-btn-container a {
  border-radius: 50%;
}

#loader {
  position: absolute;
  left: 40%;
  z-index: 1;
  width: 100px;
  height: 100px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 90px;
  height: 90px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Add animation to "page content" */

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

/* #page-wrapper {
    position: inherit !important;
    margin: 0 0 0 220px !important;
    padding: 50px 30px 0 20px;
    border-left: 1px solid #e7e7e7;
} */


.component-ajout-coach-assistant{
    
}

.component-livraison-examen-coach-view{
    
}
.content-nav-fil-actualite-livraison{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 15px 50px;
    position: fixed;
    width: 100%;
}
.nav-fil-actualite-livraison{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
    padding: 10px 0;
}
.nav-fil-actualite-livraison:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.nav-pills .nav-fil-actualite-livraison.active, .nav-pills .show>.nav-fil-actualite-livraison {
    color: #069A77;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.date-livraison-fil-d-actualite{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #DAF8F4;
    border-radius: 10px;
    text-align: center;
    color: #055F49;
    padding: 6px 12px;
    display: inline-flex;
    margin-bottom: 10px;
}
.content-fil-actualite{
/*     overflow: auto;
 */    height: 100vh;
}


.component-livraison-examen{
    
}

.component-dashboard-coach-assistant-view{
    
}
.component-dashboard-coach-assistant {
}
.form-input-dashboard-coach {
  border: 0.5px solid #069a77;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.6);
  height: 40px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.form-input-dashboard-coach:focus {
  border: 0.5px solid #069a77;
  outline: none !important;
  box-shadow: none !important;
}
.modal-content-coach {
  padding: 1rem;
  border: 0;
  position: relative;
  border-radius: 0;
}
.datapicker-custom-bakeli {
  width: 100%;
}
.select-all-label-send-task-to {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #069a77;
}
.btn-icon-table {
  padding: 0;
  margin: 0 10px;
  background: transparent;
  display: flex;
  align-items: center;
}
.icon-btn-icon-table {
  color: #b1b1b1;
  transition: 0.2s ease-in-out;
}
.icon-btn-icon-table:hover {
  color: #069a77;
  transition: 0.2s ease-in-out;
}
.todo-badge {
  background-color: #f04f43;
  color: #fff;
}
.doing-badge {
  background-color: #ffc107;
  color: #fff;
}
.done-badge {
  background-color: #58b15b;
  color: #fff;
}
.badge {
  padding: 0.3em 0.6em;
}

.button-graphe {
  margin: 20px auto;
  display: inline-block;
  background: #ff9800;
  font-family: "Muli", Arial, sans-serif;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #009688;
  padding: 3px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.button-graphe span {
  display: block;
  padding: 0 20px;
  border: 1px solid #009688;
  line-height: 42px;
  font-size: 14px;
  border-radius: 4px;

  letter-spacing: 2px;
  transition: all 0.2s linear;
}
.button-graphe :hover {
  background-color: #009688;
  -webkit-transform: translate(0px, -10px) scale(1.2);
          transform: translate(0px, -10px) scale(1.2);
}
.button-graphe span:hover {
  border: 1px solid #fff;
  color: #fff;
}

.box {
  /* margin: 50px auto; */
  text-align: right;
}

.component-table-my-bakelistes-coach-assistant {
}
.component-table-my-bakelistes {
}

.character-bullets-3 {
  list-style: square;
  cursor: default;
}

.character-bullets-2 li::before {
  content: "👎";
  margin: 0px 20px 0 0px;
}
.close-modal-bakeliste-title1 {
  color: #f33c23;
  padding: 0;
  background: transparent;
  position: absolute;
  right: 5px;
  cursor: pointer;
  /* margin: 0px 20px 0 0px; */
  /* color: pink; */
  /* font-size: 19px; */
}

.cursor {
  cursor: pointer;
}

.orange-color {
  color: #ff9800;
}

.red-color {
  color: #f34336;
}

.flex-row {
  display: flex;
}


.component-graphe-task-validated{
    
}

.component-syllabus-coach-assitant-view{
    
}.input-syllabus-search > .rs-input {
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  .add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .tope{
    top: 20px;
  }
  .icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
  }
  /* radio button */
  .btn-archive {
    /* background-color: white;
    font-size: 14px;
    font-weight: bold;
    color: #4b9b77; */
  
    font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 17px;
      /* color: white; */
      /* background: transparent; */
      display: flex;
      align-items: center;
      transition: .2s ease-in-out;
      /* border-radius: 1rem; */
      height: 36px;
      background: #469789;
      /* width: 100%; */
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      border-radius: 0.5rem;
  }
  .radiobtn {
    position: relative;
    display: block;
    padding: 1.2em;
  }
  .radiobtn label {
    display: block;
    background: #05a192cc;
    color: #fff;
    border-radius: 5px;
    padding: 8px 18px;
    border: 2px solid #009688cc;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .radiobtn label:after,
  .radiobtn label:before {
    content: "";
    position: absolute;
    right: 11px;
    top: 11px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #009688cc;
  }
  .tn-theme-light{
    padding: 0.5rem 1rem;
    background: #ffffff;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    color: #069a77;
    border: 1px solid transparent;
    
  }
  .radiobtn label:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
  }
  .radiobtn input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    -webkit-appearance: none;
            appearance: none;
  }
  .radiobtn input[type="radio"]:checked + label {
    background: #05a192cc;
    -webkit-animation-name: blink;
            animation-name: blink;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    border-color: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:after {
    background: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:before {
    width: 20px;
    height: 20px;
  }
  @-webkit-keyframes blink {
    0% {
      background-color: #05a192cc;
    }
    10% {
      background-color: #05a192cc;
    }
    11% {
      background-color: #009688cc;
    }
    29% {
      background-color: #009688cc;
    }
    30% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    45% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    100% {
      background-color: #009688cc;
    }
  }
  @keyframes blink {
    0% {
      background-color: #05a192cc;
    }
    10% {
      background-color: #05a192cc;
    }
    11% {
      background-color: #009688cc;
    }
    29% {
      background-color: #009688cc;
    }
    30% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    45% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    100% {
      background-color: #009688cc;
    }
  }
  
  .col-lg-6.col-md-4.col-sm-6.mb-4.px-lg-3.px-md-2 {
    display: inline-block;
  }
  
  .recherche{
    border-radius: 2rem;
    width: -webkit-fill-available;
    height: 33px;
    border-color: #009688cc;
  }
  
  
  

.component-syllabus-coach-assitant{
    
}.input-syllabus-search > .rs-input {
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  .mtr{
    margin-left: 6%;
  }
  .add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .tope{
    top: 20px;
  }
  .icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
  }
  /* radio button */
  .btn-archive {
    /* background-color: white;
    font-size: 14px;
    font-weight: bold;
    color: #4b9b77; */
  
    font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 17px;
      /* color: white; */
      /* background: transparent; */
      display: flex;
      align-items: center;
      transition: .2s ease-in-out;
      /* border-radius: 1rem; */
      height: 36px;
      background: #469789;
      /* width: 100%; */
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      border-radius: 0.5rem;
  }
  .radiobtn {
    position: relative;
    display: block;
    padding: 1.2em;
  }
  .radiobtn label {
    display: block;
    background: #05a192cc;
    color: #fff;
    border-radius: 5px;
    padding: 8px 18px;
    border: 2px solid #009688cc;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .radiobtn label:after,
  .radiobtn label:before {
    content: "";
    position: absolute;
    right: 11px;
    top: 11px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #009688cc;
  }
  .tn-theme-light{
    padding: 0.5rem 1rem;
    background: #ffffff;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    color: #069a77;
    border: 1px solid transparent;
    
  }
  .radiobtn label:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
  }
  .radiobtn input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    -webkit-appearance: none;
            appearance: none;
  }
  .radiobtn input[type="radio"]:checked + label {
    background: #05a192cc;
    -webkit-animation-name: blink;
            animation-name: blink;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    border-color: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:after {
    background: #009688cc;
  }
  .radiobtn input[type="radio"]:checked + label:before {
    width: 20px;
    height: 20px;
  }
  @-webkit-keyframes blink {
    0% {
      background-color: #05a192cc;
    }
    10% {
      background-color: #05a192cc;
    }
    11% {
      background-color: #009688cc;
    }
    29% {
      background-color: #009688cc;
    }
    30% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    45% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    100% {
      background-color: #009688cc;
    }
  }
  @keyframes blink {
    0% {
      background-color: #05a192cc;
    }
    10% {
      background-color: #05a192cc;
    }
    11% {
      background-color: #009688cc;
    }
    29% {
      background-color: #009688cc;
    }
    30% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    45% {
      background-color: #009688cc;
    }
    50% {
      background-color: #05a192cc;
    }
    100% {
      background-color: #009688cc;
    }
  }
  
  .col-lg-6.col-md-4.col-sm-6.mb-4.px-lg-3.px-md-2 {
    display: inline-block;
  }
  
  .recherche{
    border-radius: 2rem;
    width: -webkit-fill-available;
    height: 33px;
    border-color: #009688cc;
  }
  
  
  

.component-syllabus-item-coach-assistant{
    
}.syllabus-item {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 15px;
}

.container-syllabus-item-back {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.74);
    border-radius: 15px;
    padding: 2rem 2rem 3rem;
}

.container-syllabus-item{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    position: relative;
}

.container-syllabus-item:hover .syllabus-item {
    /* opacity: 0.3; */
}

.container-syllabus-item:hover .container-syllabus-item-back {
    opacity: 1;
}

.content-syllabus-item{
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    position: relative;
}
.image-syllabus-item{
    border-radius: 15px 15px 0 0;
    width: 100%;
}

.titre-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #0F0F0F;
    margin-bottom: 15px;
}
.icon-content-syllabus-item{
    color: #009688;
    width: 12px;
    margin-right: 10px;
}
.info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
.titre-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.content-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: inline;
}
.etiquette-syllabus-item{
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.etiquette-syllabus-item.debutant{
    background: #EC0303;
}
.etiquette-syllabus-item.confirme{
    background: #069A77;
}
.bg-debutant {
    background: #dd4b39 !important;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
  }
.bg-intermediaire {
    background:#f3bc07;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.bg-avance {
    background: #069A77;
    position: absolute;
    top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding: 4px 20px 4px 10px;
    border-radius: 0 30px 30px 0;
}
.position-action-container-syllabus-item-back{
    position: absolute;
    left: 15px;
    width: 100%;
    bottom: 15px;
}
.link-details-container-syllabus-item-back{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .1s ease-in-out;
    cursor:pointer
}
.link-details-container-syllabus-item-back:hover{
    color: #FFFFFF;
    transition: .1s ease-in-out;
    text-decoration: none;
}
.icon-details-container-syllabus-item-back{
    width: 15px;
    margin-right: 8px;
}
.height-container-syllabus-item-back{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.component-share-to-coach-assistant{
    
}.btn-share-to{
    padding: 0;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffffc9;
    transition: .2s ease-in-out;
}
.btn-share-to:hover{
    background: none;
    color: #ffffff;
    transition: .2s ease-in-out;
}

.component-syllabus-details-coach-assistant-view2{
    
}

.component-syllabus-details-coach-assistant2{
    
}

.component-syllabus-coach-inner-details-assistant{
    
}
.fts{
    font-size: 15px;
}

.component-ajout-tache-syllabus-coach-assistant{
    
}.groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #069A77;
}


.component-groupe-coach-assistant-view{
    
}

.component-groupe-coach-assistant{
    
}

.component-liste-sous-groupes-coach-assistant-view{
    
}

.component-liste-sous-groupes-coach-assistant{
    
}

.component-ajout-groupe-coach-assistant{
    
}

.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}


.component-ajout-sous-groupe-coach-assistant{
    
}

.component-table-my-bakelistes{
    
}

.form-input-dashboard-coach{
    border: 0.5px solid #069A77;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    height: 40px;
    padding-left: 2rem;
    padding-right: 2rem;
}
.form-input-dashboard-coach:focus{
    border: 0.5px solid #069A77;
    outline: none !important;
    box-shadow: none !important;
}
.modal-content-coach{
    padding: 1rem;
    border: 0;
    position: relative;
    border-radius: 0;
}
.datapicker-custom-bakeli{
    width: 100%;
}
.select-all-label-send-task-to{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #069A77;
}
.btn-icon-table{
    padding: 0;
    margin: 0 10px;
    background: transparent;
    display: flex;
    align-items: center;
}
.icon-btn-icon-table{
    color: #B1B1B1;
    transition: .2s ease-in-out;
}
.icon-btn-icon-table:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.todo-badge{
    background-color: #f04f43;
    color: #fff;
}
.doing-badge{
    background-color: #ffc107;
    color: #fff;
}
.done-badge{
    background-color: #58b15b;
    color: #fff;
}
.badge {
    padding: .30em .6em;
}


.component-details-examen-coach-assistant-view{
    
}

.component-details-examen-pratique-coach-assistant{
    
}

.component-detail-examen-pratique-coach-assistant-view{
    
}

.component-details-examen-coach-assistant{
    
}

.component-details-coach-assistant-examen{
    
}

.component-add-question-examen-coach-assistant{
    
}

.component-livrable-coach-assistant-view{
    
}.content-nav-fil-actualite-livraison{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 15px 50px;
    position: fixed;
    width: 100%;
}
.nav-fil-actualite-livraison{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    transition: .2s ease-in-out;
    padding: 10px 0;
}
.nav-fil-actualite-livraison:hover{
    color: #069A77;
    transition: .2s ease-in-out;
}
.nav-pills .nav-fil-actualite-livraison.active, .nav-pills .show>.nav-fil-actualite-livraison {
    color: #069A77;
    background-color: transparent;
    transition: .2s ease-in-out;
}
.date-livraison-fil-d-actualite{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background: #DAF8F4;
    border-radius: 10px;
    text-align: center;
    color: #055F49;
    padding: 6px 12px;
    display: inline-flex;
    margin-bottom: 10px;
}
.content-fil-actualite{
/*     overflow: auto;
 */    height: 100vh;
}


.component-edit-question-coach-assistant{
    
}

.component-view-edit-question-coach{
    
}

.component-resset-password{
    
}


.component-livraison-dun-bakeliste-view{
    
}

.component-livraison-dun-bakeliste{
    
}

.component-livrable-dun-bakeliste-item{
    
}

.component-livraison-dun-bakeliste-coach-assistant-view{
    
}

.component-livraison-dun-bakeliste-coach-assistant{
    
}

.component-voir-mes-contenus-view{
    
}

.component-voir-mes-contenus{
    
}
.place-folder {
    width: 207.57px;
    height: 173.76px;
    left: 585.02px;
    /* top: 162px; */
    padding-bottom: 15px;
    margin-top: 20px;
    cursor: pointer;
  }
 .texte_centrer {
    font-size: 14px;
    font-weight: bold;
    color: #469789;
    text-decoration: underline;
}
.scroll {
    overflow: scroll;
    height: 100vh;
}
.bordure {
    border: 1px #009688 solid;
    border-radius: 1rem;
}
.nom {
    margin-left: 2rem;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.vp{
    padding-bottom: 3%;
    padding-top: 5%;
}
.archiveColor{
    color: #eb4234;
}

.component-contenus-view{
    
}

.component-contenus{
    
}
.images-aita-card-center{
    text-align: center;
}
/* .images-aita-card-center img{
    width: 100px;
    height: 100px;
} */
.images-aita-card-center h5{
    color: #009688;
    padding-top: 10px;
}
.auteur{
    color:#069A77;
    size: 12px;
    text-decoration: underline;
    font-weight: bold;
}
.ecrit{
    color:#069A77;
    size: 12px;
    text-decoration: underline;
    font-weight: bold;
}
.bot{
    padding-top: 8%;
}

.component-voir-contenus-coach-view{
    
}

.component-voir-contenus-coach{
    
}
.component-contenu-liste {
}
body {
  background: #f2f2f2;
  font-family: "proxima-nova-soft", sans-serif;
  font-size: 14px;
}
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: white;
  min-width: 270px;
  height: 470px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.post-module:hover .thumbnail img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.5;
}
.post-module .thumbnail {
  background: white;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  color: white;
  font-weight: 700;
  text-align: center;
}

.post-module .thumbnail .auteur {
  position: absolute;
  top: 20px;
  /* right: 20px; */
  z-index: 1;
  background: #009688;
  width: 39%;
  height: 43px;
  padding: 12.5px 0;
  color: white;
  font-weight: 700;
  text-align: center;
  -webkit-text-decoration: auto;
          text-decoration: auto;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  /* padding: 30px; */
}
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #e74c3c;
  padding: 10px 15px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: black;
  font-size: 18px;
  height: 24vh;
  line-height: 7vh;
  font-weight: 600;
}

.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  color: #3f4142;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #ff9800;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: bisque;
  text-decoration: none;
}
.hover .post-module:hover all .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.container-content:before,
.container-content:after {
  content: "";
  display: block;
  clear: both;
}
.container .column {
  width: 100%;
  /* padding: 0 25px; */
  height: 100%;
  /* float: left; */

  
  padding: 2rem 2rem 3rem ;
}
.container .column .demo-title {
  margin: 0 0 15px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.info-content {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}
.info-content h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
}
.info-content span {
  color: black;
  font-size: 18px;
}

.icon-content-syllabus-itemC {
  color: #e74c3c;
  width: 22px;
  margin-left: 5rem;
  cursor: pointer;
}
.icon-content-syllabus-itemE {
  color: #009688;
  width: 22px;
  margin-left: 5rem;
  cursor: pointer;
}
.container .info span a {
  color: violet;
  text-decoration: none;
}
.info-content span .fa {
  color: #e74c3c;
}
.tb {
  margin-bottom: 25px;
}
.chercher{
  margin-bottom: 5%;
  margin-left: 2px;
  margin-right: 2px;

}
.row .content-block {
  width: 100%;
  overflow-y: hidden;
}

.input-group-prepend.SearchB.offset-md-5 {
  margin-bottom: 10px;
}

.component-contenus-coach-view{
    
}

.component-contenus-coach{
    
}

article {
  height: 100%;
}

.image {
  background-image: url("https://images.unsplash.com/photo-1516571137133-1be29e37143a?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=98feb08eaacddba8c029c8b5298ca3a0");
  background-size: cover;
}

.headline {
  background-color: #009688;
  color: #e8ac52;
  padding: 0.75rem;
  text-align: center;
  box-shadow: 0 1.2em 0 0.75em;
}

.headline p {
  font-size: 1.25em;
}
.headline h1 {
  margin-top: -0.25em;
  margin-bottom: 0.5em;
  font-size: 3em;
  text-transform: uppercase;
  line-height: 1;
  text-shadow: 3px 3px 0px #009688;
}

.byline {
  margin: 0 1rem 1rem 1rem;
  font-family: "Libre Baskerville", serif;
  font-style: italic;
  letter-spacing: -0.2px;
}

.article-text {
  margin: -1em auto;
  padding: 0.5em 1em;
  max-width: 620px;
  font-size: 1em;
  line-height: 1.25;
  color: #3e2723;
}

.first::first-letter {
  float: left;
  margin: 0 0.2em 0 0;
  color: 009688;
  font-size: 4em;
  font-family: "Libre Baskerville", serif;
  font-weight: 600;
  text-shadow: 2px 2px 0px #009688;
}

/* CSS Grid starts here! */

.article-header {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 2fr 1fr 1fr 1fr;
}

.image {
  grid-column: 1 / -1;
  grid-row: 1 / 3;
}
.headline {
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  z-index: 100;
}


.component-content-comments{
    
}

.component-voir-contenus-coach-assistant-view{
    
}

.component-voir-contenus-coach-assistant{
    
}

.component-contenus-coach-assistant-view{
    
}

.component-contenus-coach-assistant{
    
}

.component-voir-contenus-bakeliste-view{
    
}

.component-voir-contenus-bakeliste{
    
}
.mgl {
    padding-left: 30%;
}

.radiobtn1 {
    position: relative;
    display: block;
  }
  .radiobtn1 label {
    display: block;
    background: #fee8c3;
    color: #444;
    border-radius: 5px;
    padding: 10px 20px;
    border: 2px solid #fdd591;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .radiobtn1 label:after,
  .radiobtn1 label:before {
    content: "";
    position: absolute;
    right: 11px;
    top: 11px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: #fdcb77;
  }
  .radiobtn1 label:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
  }
  .radiobtn1 input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    -webkit-appearance: none;
            appearance: none;
  }
  .radiobtn1 input[type="radio"]:checked + label {
    background: #fdcb77;
    -webkit-animation-name: blink1;
            animation-name: blink1;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    border-color: #fcae2c;
  }
  .radiobtn1 input[type="radio"]:checked + label:after {
    background: #fcae2c;
  }
  .radiobtn1 input[type="radio"]:checked + label:before {
    width: 20px;
    height: 20px;
  }
  
  @-webkit-keyframes blink1 {
    0% {
      background-color: #fdcb77;
    }
    10% {
      background-color: #fdcb77;
    }
    11% {
      background-color: #fdd591;
    }
    29% {
      background-color: #fdd591;
    }
    30% {
      background-color: #fdcb77;
    }
    50% {
      background-color: #fdd591;
    }
    45% {
      background-color: #fdcb77;
    }
    50% {
      background-color: #fdd591;
    }
    100% {
      background-color: #fdcb77;
    }
  }
  
  @keyframes blink1 {
    0% {
      background-color: #fdcb77;
    }
    10% {
      background-color: #fdcb77;
    }
    11% {
      background-color: #fdd591;
    }
    29% {
      background-color: #fdd591;
    }
    30% {
      background-color: #fdcb77;
    }
    50% {
      background-color: #fdd591;
    }
    45% {
      background-color: #fdcb77;
    }
    50% {
      background-color: #fdd591;
    }
    100% {
      background-color: #fdcb77;
    }
  }

.component-contenus-bakelistes-view{
    
}

.component-contenus-bakelistes{
    
}

.component-detail-contenus-valides-view{
    
}

.component-detail-contenus-valides{
    
}

.component-contenus-valides-view{
    
}

.component-contenus-valides{
    
}

.component-liste-bakeliste-coach-assistant-view{
    
}

.component-liste-bakeliste-coach-assistant{
    
}

.component-not-found-view{
    
}

.component-not-found{
    
}

.component-modifier-resume-view{
    
}

.component-modifier-resume{
    
}

.component-taches-bakeliste-validees-view{
    
}

.component-taches-bakeliste-validees{
    
}

.component-liste-contenus-bakeliste-view{
    
}

.component-liste-contenus-bakeliste{
    
}

.component-cv-bakeliste-view{
    
}
.component-cv-bakelistes {
}
.validation-btn-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.validation-btn-container i {
  font-size: 62px;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn {
  width: 90px;
  height: 90px;
  border-radius: 15% 50% 15% 50%;
}

button.btn.btn-success.volkeno-orange-bcolor.validation-btn:hover {
  background: #009688 !important;
}

.component-card-profil {
}

.card-profil-contain {
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 95%;
}
.image-profil {
  width: 250px;
  height: 250px;
}
.contain-card {
  display: block;
  text-align: center;
  justify-content: center;
}
.nom-profil {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 47px;
  color: #009688;
}
.contain-profil {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  color: rgba(0, 0, 0, 0.8);
}

/* .breadcrumb {
    background-color: white;
} */
.lien_nav {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.lien_nav:hover {
  list-style: none;
  text-decoration: none;
  color: #666666;
}
.lien_nav_current {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #009688 !important;
  padding-left: 1rem;
}

.component-card-info {
}
.modal-dialog {
  max-width: 1100px;
}
.titre-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #009688;
}
.input-texte {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.label-texte {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #666666;
}
.texte-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
}
.contain-info {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 2rem;
}
.btn-presentation {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #666666;
  background: #ffffff;
  border: 1px solid #009688;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
  width: 50%;
}
.texte-info-contain {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
  padding-left: 17rem;
}
.marge-contain {
  padding-top: 7rem;
}
.info-contain-right {
  text-align: right;
}
.video-modal {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .info-contain-left {
    text-align: left;
    padding: -1rem;
    margin: 0;
  }
  .texte-info-contain {
    padding-left: 0;
  }
  .btn-presentation {
    padding-left: -1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .form-info {
    display: block;
    width: 100%;
  }

  .texte-info-contain {
    padding-left: 0;
  }
  .presentation-info {
    text-align: left;
  }
  .btn-presentation {
    width: 100%;
    padding: 1rem;
  }
  .titre-info {
    font-weight: 200;
    font-size: 18px;
  }
}

.info-container {
  background: #ffffff;
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.title-cv {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #009688;
}

/* Accordion */

.accordion__container {
  margin-bottom: 10px;
  background-image: linear-gradient(to bottom left, #ffffff, #00968840);
  background-repeat: no-repeat;
  padding: 1em 2em;
  box-shadow: 0 5px 8px rgb(255, 255, 255);
  width: 100%;
}
.accordion__container:last-type {
  margin-bottom: 0;
}
p.accordion__answer {
  color: black;
  font-family: Montserrat;
  line-height: 21px;
}
.accordion__question__container {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8em;
}
.accordion__question {
  color: orangered;
}
.accordion__btn {
  display: inline-block;
  background: none;
  border: none;
  border: 2px solid currentColor;
  border-radius: 3px;
  color: #e08152;
  cursor: pointer;
}
.accordion__btn i {
  font-size: 1.2em;
  padding: 0.15em;
}
.accordion__answer__container {
  display: none;
}
.active__accordion {
  display: block;
}
span.diplome_label {
  font-weight: 600;
}
h5.project_name_label {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  color: #000000;
}


.component-ajout-cv-bakeliste-view{
    
}

.component-ajout-cv-bakeliste{

}
.chevron {
    color: whitesmoke;
    position: absolute;
    /* left: 15%; */
    /* right: 15%; */
    /* top: 70%; */
    /* bottom: -10%; */
}
.iconeVoir{
    color: #ff9800;
}
.imagePortfolio {
    width: 100%;
}
.edit-education{
    color: #397973;
    margin-left: 20%;
}
.edit-tache{
    color: #397973;
    margin-left: 20%;
    width: 15px;
}
.according-text{
background-color: #397973;
color: whitesmoke;
size: 14;
font-weight: bold;
}
.h61{
    margin-bottom: 11px;
    text-decoration: underline;
}
.accordion {
    margin-bottom: 3%;
    cursor: pointer;
}
.fmTa{
    /* vert */
border: 1px solid #069A77;
box-sizing: border-box;
/* Drop Shadow */

box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.carde{
    border:none;
}
.panel-volkeno-green .panel-footer {
    color: #009688 !important;
  }
  .panel-volkeno-orange .panel-footer {
    color: #ff9800 !important;
  }
  .panel-volkeno-red .panel-footer {
    color: #f44336 !important;
  }
.formrow{
    padding-top: 1,25rem;
}
.col-md-12.motivations_item {
    padding: 2rem;
}
h5.project_name_label {
    padding: 1rem;
}
.col-md-12.education_item_container.formrow {
    padding: 1rem;
}
.bodyAcordion{
    background-color: white;
    padding: 1rem;
    /* padding-left: 1rem; */

}
.newform{
    padding-left: 2rem;
}
.bodyAcordionPortfolio{
    background-color: white;
    padding: 1rem;
    /* padding-left: 1rem; */
}
.fmCv{
    background: #FFFFFF;
/* gris 6 */

border: 1px solid #666666;
box-sizing: border-box;
/* Drop Shadow */

box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
border-radius: 5px;
}
.img.img_responsive.portfolio_img {
    width: 100%;
}

.portfolio_item_container .panel-footer {
    cursor: pointer;
    text-align: center;
    color: #009688;
}
.import-item-panel-container .panel-heading {
    background: #009688;
    text-align: center;
    padding: 20px;
    color: #fff;
}
.import-item-panel-container .panel-body {
    font-size: 16px;
    text-align: center;
}
.import-item-panel-container .panel.panel-default {
    border-color: #009688 !important;
}
.import-item-panel-container i.fas.fa-file-import {
    font-size: 35px;
}
.import-item-panel-container h3.panel-title {
    font-size: 25px;
}
.import-item-panel-container .panel-footer {
    padding: 15px;
    font-size: 31px;
    text-align: center;
    color: #009688;
    font-weight: 600;
    cursor: pointer;
}
.portfolio-card{
    padding: 2%;
}
.svg.feather.feather-eye {
    color: #f39831;
    width: 40%;
    margin-left: 3rem;
    /* font-weight: bold; */
}
.span-projet{
    text-decoration: underline;
    color:#009688 ;
    font-weight: bold;
}

.modal-90w{
width: 50vw;
}

.component-coach-ajout-contenu-view{
    
}

.component-coach-ajout-contenu{
    
}

.component-coach-assistant-ajout-contenu-view{
    
}

.component-coach-assistant-ajout-contenu{
    
}

.component-coach-productions-view{
    
}

.component-coach-productions{
    
}

.component-modifier-productions-view{
    
}

.component-modifier-productions{
    
}

.component-coach-assistant-productions-view{
    
}

.component-coach-assistant-productions{
    
}

.component-modifier-productions-coach-assistant-view{
    
}

.component-modifier-productions-coach-assistant{
    
}

.component-show-all-livraisonsv2{
    
}

.component-dashboard-c-a-view-r{
    
}

.component-dashboard-c-a-r{
    
}
