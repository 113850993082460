.btn-like-fil-actualite-card {
  right: 15px;
  background: transparent;
  color: #e0e0e0;
  transition: 0.2s ease-out;
}
.btn-like-fil-actualite-card:hover {
  color: #055f49;
  transition: 0.2s ease-in;
}
.icon-btn-like-fil-actualite-card {
  width: 18px;
}
.pComment {
  color: #dc3545;
  font-size: 16px;
  font-weight: 700;
}
.card-fil-actualite {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.avatar-fil-actualite {
  width: 50px;
  margin-right: 10px;
}
.name-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 10px;
}
.heure-fil-actualite {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.8);
}
.icon-comment-fil-actualite {
  width: 16px;
}
.input-comment-fil-actualite {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  height: 35px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 40px !important;
  padding-right: 60px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-send-comment-fil-actualite {
  /* position: absolute;
  z-index: 9999; */
  right: 15px;
  top: 5px;
  padding: 0;
  background: transparent;
}
.icon-btn-send-comment-fil-actualite {
  width: 22px;
}

/* Gallery */

.ReactGridGallery {
  display: flow-root;
  flex-direction: column;
  width: 100%;
  /* background: orange; */
}
.ReactGridGallery > .ReactGridGallery_tile {
  width: max-content;
  /* border: 1px solid red; */
  float: left !important;
}
