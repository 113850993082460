.component-content-examen-bakeliste {
}

.component-content-exam-theorique-two {
}

.oo{
  margin-left: 9%;
}

.nav-theorique {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #009688;
  border: none;
}
.nav-theorique:active {
  border: none;
}

.card-exam {
  height: 100%;
  border: none;
  border-radius: 2px;
  background: #fff;
}

.partie-theorique {
  background: #009688;
  padding-top: 2rem;
  padding-left: 2rem;
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.part-article {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}
.part-article h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.part-article-par p {
  width: 279px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.part-article1 {
  width: 154px;
  height: 43px;
  background: #ffffff;
  border-radius: 20px;
}

.part-article1 h1 {
  padding-top: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.progress {
  width: 185px;
  height: 15px;
  left: 511px;
  top: 268px;
  background: #01564e;
  border-radius: 20px;
}
.progress-bar {
  /* width: 75%; */
  width: 100%;
  background: #ff9800;
  border-radius: 20px;
}

.part-two-title p {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}

.exam-question-one {
  width: 98%;
  height: 66px;
  background: #009688;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.exam-question-one p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #ffffff;
}

.btn-precedant {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #ff9800;
  border-radius: 10px;
}

.exam-btn-precedant {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}

.exam-btn-precedant:hover {
  color: white;
  text-decoration: none;
}

.btn-voir-resultat {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #009688;
  border-radius: 10px;
}

.exam-btn-voir-resultat {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}
.exam-btn-voir-resultat:hover {
  color: white;
  text-decoration: none;
}

.card-exam-pratique {
  border: none;
  background: #ffffff;
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
}

.exam-pratique-entete {
  width: 100%;
  height: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.exam-pratique-entete p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.modul-exam p {
  font-weight: 600;
}

.sujet-pratique p {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.domaine p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.domaine span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.niveau p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.niveau span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.duree p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.duree span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.sujet p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.sujet span {
  padding-left: 1rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.sujet-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.travail-a-faire p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.travail-a-faire-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-one span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-one p i {
  color: #666666;
}
.travail-two span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}
.travail-two p i {
  color: #666666;
}

.nb p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
  color: #000000;
}
.nb span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: rgba(0, 0, 0, 0.8);
}

.btn-precedant-pratique {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #ff9800;
  border-radius: 10px;
}

.exam-btn-precedant-pratique {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}

.exam-btn-precedant-pratique:hover {
  color: white;
  text-decoration: none;
}

.btn-voir-resultat-pratique {
  text-align: center;
  width: 314.17px;
  height: 50px;
  background: #009688;
  border-radius: 10px;
}

.exam-btn-voir-resultat-pratique {
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.055em;
}
.exam-btn-voir-resultat-pratique:hover {
  color: white;
  text-decoration: none;
}
.start-exam {
  background: white;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}
