
.component-examen-coach-assistant{
    
}
.component-examen-coach{
    
}
.nav-pills1{
    width: 100%;
    padding-top: 3rem;
    /* padding-left: 3rem; */
    padding-bottom: 1rem;
}
.ddr{
    margin: 1rem;

}
.nav-item1{
    font-size: 15px;
    font-weight: bold;
}
.nav-link1{
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    background-color: #009688;
    color: white; 
    border-radius: 0px 20px 0px; 
    border: #dbf1ef 1px solid;
    font-family: 'Montserrat';
    border: #dbf1ef 1px solid;

}
.nav-link1:hover{
    color: white;
}
.nav-link1.active{
    background-color:inherit;
    color: #009688;
    border-radius: inherit;
    border-bottom: 1px solid;
    border-top: inherit;
    border-left: inherit;
    border-right: inherit;

    /* border-radius: 10px; */
}