
.component-contenus{
    
}
.images-aita-card-center{
    text-align: center;
}
/* .images-aita-card-center img{
    width: 100px;
    height: 100px;
} */
.images-aita-card-center h5{
    color: #009688;
    padding-top: 10px;
}
.auteur{
    color:#069A77;
    size: 12px;
    text-decoration: underline;
    font-weight: bold;
}
.ecrit{
    color:#069A77;
    size: 12px;
    text-decoration: underline;
    font-weight: bold;
}
.bot{
    padding-top: 8%;
}