.groupe-titre-on-coach-dashboard{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px ;
    line-height: 29px;
    color: #069A77;
}
.btn-add-groupe{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-archive{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 17px;
    /* color: white; */
    /* background: transparent; */
    display: flex;
    align-items: center;
    transition: .2s ease-in-out; 
    /* border-radius: 1rem; */
    height: 36px;
    background: #469789;
    /* width: 100%; */
    width: fit-content;

    border-radius: 0.5rem;
}
.btn-modifier{
    background: transparent;
    font-size: 14px;
    line-height: 17px;
    color: #469789;; 
    /* border-radius: 1rem; */
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-top: 10px;


}

  
.lien-whatsapp {
    font-size: 14px;
    display: block;
    line-height: 1.3;
    margin-bottom: 5px;
    color: blue;
    text-decoration: underline;

    
  }
.btn-archiver{
     background: transparent;
    /* font-size: 12px;
    line-height: 17px; */
    color: #e6ab0a;
    margin-top: 13px;
}
.icon-add-groupe{
    font-size: 20px;
    color: #FFFFFF;
}
.add-group{
    color: white;
    font-size: 14px;
    font-weight: bold;
}