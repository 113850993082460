.component-content-liste-questions {
}

.orange-icon {
  color: #ff9800;
  cursor: pointer;
}

.red-icon {
  color: #f44336;
  cursor: pointer;
}

.green-icon {
  color: #009688;
}
